
import PropTypes from 'prop-types';
import React, { Fragment, useEffect, useState, useRef } from "react";
import { useNavigate, useParams, Link } from "react-router-dom";
import { useDispatch, useSelector } from "../store";
import { getProfile, clearProfile } from "../store/profile";
import moment from "moment";
import Loader from "../Loader";
import { Swiper, SwiperSlide } from "swiper/react";
import cover from "../assets/img/cover-img.png";
import friends from "../assets/img/friends.svg";
import date from "../assets/img/date.svg";
import hash1 from "../assets/img/hash1.svg";
import hash from "../assets/img/hash.svg";
import Typography from "@mui/material/Typography";
import { abbreviateNumber } from "../utils/helper";
import HoverPopover from "material-ui-popup-state/HoverPopover";
import "./index.css";
import { Dialog, DialogContent, DialogActions, Avatar, Divider } from '@mui/material';
import ClickAwayListener from "@mui/material/ClickAwayListener";
import dark from '../assets/img/darkapple.svg';
import playstore from '../assets/img/Playstore.svg';
import white from '../assets/img/whiteapple.svg';

const Profile = ({ settingsDetails }) => {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const { profileid } = useParams();
	const { respStatus, profileDetails } = useSelector((state) => state.profile);
	const [profile, setProfile] = useState({});
	const [avatar, setAvatar] = useState("");
	const [avatarEmtyString, setAvatarEmtyString] = useState("");
	const [anchorEl, setAnchorEl] = useState(null);
	const [openPopoverId, setOpenPopoverId] = useState(null);

	const homepageURL = () => {
		navigate("/");
	};
	useEffect(() => {
		dispatch(clearProfile());
		let apiUrl = `user/site/get-profile`;
		dispatch(getProfile(apiUrl, profileid));
	}, [profileid, dispatch]);

	useEffect(() => {
		if (respStatus && respStatus === 1) {
			setProfile(profileDetails);
		}
	}, [profileDetails, respStatus]);

	function groupBy(arr) {
		return arr?.reduce(function (obj, item) {
			obj[item.type] = obj[item.type] || [];
			obj[item.type].push({
				url: item?.username,
				_id: item?._id,
				logo: item?.logo,
				title: item?.title,
			});
			return obj;
		}, {});
	}
	const socialLinksData = groupBy(profileDetails?.social_order || []);
	const socialLinks = Object.keys(socialLinksData).map(function (key) {
		return { name: key, data: socialLinksData[key] };
	});
	const profileDetailsRef = useRef(profileDetails);

	useEffect(() => {
		if (profileDetailsRef.current?._id || profile?._id) {
			const updatedProfileDetails = profile?._id
				? profile
				: profileDetailsRef.current;

			setAvatar(updatedProfileDetails?.avatar || "");

			let matches = updatedProfileDetails?.username?.match(/\b(\w)/g);
			let shortName = matches?.join("") || "";
			if (matches.length === 1) {
				shortName = updatedProfileDetails?.username?.substring(0, 2);
			} else if (matches.length > 3) {
				shortName = matches?.splice(0, 3)?.join("") || "";
			}
			setAvatarEmtyString(shortName);
		}
	}, [profile]);

	const [open, setOpen] = useState(false);

	const handleClickOpen = () => {
		setOpen(true);
	};

	const handleClose = () => {
		setOpen(false);
	};
	const handleDialogOpen = () => {
		setOpen(true);
	};

	const StoreRedirect = (url) => {
		let a = document.createElement("a");
		a.href = url;
		a.target = "_blank";
		a.click();
		// handleClose();
	}


	return (
		<Fragment>
			{!profileDetails?._id && <Loader />}
			{profileDetails?._id && (
				<div className="mx-auto flex flex-col justify-start scrollbarhide">
					<div className="relative w-full h-auto flex justify-center">
						<div
							className="min-h-screen max-w-[1024px] container static lg:p-[0px] md:w-[90%] lg:w-[96%] flex flex-col"
							style={{
								backgroundColor:
									profileDetails.darkMode === 0 ? "#f3f2f7" : "#151515",
								opacity: profileDetails.darkMode === 0 ? "1" : "0.70",
							}}
						>
							<div className="flex-grow">
								<div className="relative">
									<img
										className="h-[244px] 5xl:h-[350px] w-full object-cover"
										src={profile?.banner_img || cover}
										alt="cover-img.png"
									/>
								</div>
								<div className="px-[10px] relative">
									<div className="relative text-center z-1 mt-[-96px] md:mt-[-96px] 5xl:mt-[-103px]">
										<span>
											<Avatar
												className="text-center mx-auto object-cover border-[2px] border-[#fff] rounded-[50%]"
												alt={avatarEmtyString}
												src={avatar}
												sx={{
													width: "188px",
													height: "188px",
													fontSize:
														avatarEmtyString.length === 2 ? "82px" : "60px",
													bgcolor: "#0078bb",
													color: "#fff",
													textTransform: "uppercase",
												}}
											>
												{avatarEmtyString}
											</Avatar>
										</span>
										<h2
											className="text-[26px] 5xl:text-[30px] font-[650] mt-[18px] 5xl:mt-[26px] leading-[24px]"
											style={{
												color:
													profileDetails.darkMode === 0 ? "#121111" : "#fff",
											}}
										>
											{profile?.username}
										</h2>
										<p
											className="text-[#0070bb] text-[14px] 5xl:text-[22px] font-[400] mt-[8px] 5xl:mt-[16px] profile-id leading-[20px]"
											style={{
												textDecorationColor:
													profileDetails.darkMode === 0 ? "#f3f2f7" : "#151515",
											}}
										>
											#{profile?.aeedee_id?.replace(/\+/g, "")}
										</p>
									</div>
									<React.Fragment>
										<Dialog
											onClose={handleClose}
											aria-labelledby="customized-dialog-title"
											open={open}
											sx={{
												'& .MuiDialog-paper': {
													backgroundColor:
														profileDetails.darkMode === 0 ? "#f0f0f5" : "#202020",
													color:
														profileDetails.darkMode === 0 ? "#222222" : "#fff",
													borderRadius: '10px',
												},
											}}
										>
											<DialogContent
												sx={{
													paddingTop: '20px',
													paddingLeft: '20px',
													paddingRight: '20px',
													paddingBottom: '0px',

													textAlign: 'center'
												}}
											>
												<Typography sx={{ fontFamily: 'Figtree' }}>
													Please download the <span className='font-bold'>AeeDee App</span> for this feature to work
												</Typography>
											</DialogContent>
											<DialogActions
												sx={{
													display: 'flex',
													justifyContent: 'center',
													padding: '0px'
												}}
											>
												<div className="flex flex-col md:flex-row md:justify-center lg:justify-start gap-[20px] my-[20px] ">
													<button type="button" style={{
														color: profileDetails.darkMode === 0 ? "#222222" : "#fff",
														borderColor: profileDetails.darkMode === 0 ? '#000' : '#fff',
													}}
														className="justify-center border font-medium rounded-[56px] text-[16px] px-[20px] py-[8px]  flex items-center space-x-2"
														onClick={() => StoreRedirect(settingsDetails?.app_store_link || "https://www.apple.com/")}
													>
														<img src={profileDetails.darkMode === 0 ? dark : white} alt="appstore" />
														<span>App Store</span>
													</button>
													<button type="button" style={{
														color: profileDetails.darkMode === 0 ? "#222222" : "#fff",
														borderColor: profileDetails.darkMode === 0 ? '#000' : '#fff',
													}}
														className="justify-center border  font-medium rounded-[56px] text-[16px] px-[20px] py-[8px] flex items-center space-x-2"
														onClick={() => StoreRedirect(settingsDetails?.google_play_link || "https://www.google.com/")}
													>
														<img src={playstore} alt="playstore" />
														<span>Google Play</span>
													</button>
												</div>
											</DialogActions>
										</Dialog>
										<div className="flex justify-center gap-[6px] mx-[0.5rem] mt-4 block lg:hidden">
											<button
												type="button"
												className="h-[33px] bg-[#dadcde] text-[#828282] font-[600] rounded-[17px] text-[14px] pb-[0px] px-[18px] text-center"
												onClick={handleClickOpen}
											>
												Follow
											</button>
											<button
												type="button"
												className="h-[33px] bg-[#dadcde] text-[#828282] font-[600] rounded-[17px] text-[14px] pb-[0px] px-[18px] text-center"
												onClick={handleClickOpen}
											>
												Chat
											</button>
											{profileDetails?.savecntrl?.allowContact === 1 &&
												profileDetails?.savecntrl?.public === 1 && (
													<button
														type="button"
														className="h-[33px] text-[#0070BB] border border-[#0070BB] font-[600] rounded-[17px] text-[14px] pb-[0px] px-[18px] text-center"
														onClick={async (e) => {
															e.preventDefault();
															if (profile?.phone?.number) {
																let contact = {
																	name: profile?.username || "",
																	phone: profile?.phone?.number,
																	email: profile?.email || "",
																};
																let vcard =
																	"BEGIN:VCARD\nVERSION:4.0\nFN:" +
																	contact?.name +
																	"\nTEL;TYPE=work,voice:" +
																	contact?.phone +
																	"\nEMAIL:" +
																	contact?.email +
																	"\nEND:VCARD";
																let blob = new Blob([vcard], {
																	type: "text/vcard",
																});
																let url = URL.createObjectURL(blob);
																const newLink = document.createElement("a");
																newLink.download = contact?.name + ".vcf";
																newLink.textContent = contact?.name;
																newLink.href = url;
																newLink.click();
															}
														}}
													>
														Save Contact
													</button>
												)}
										</div>
									</React.Fragment>
									<div className="flex flex-wrap justify-center items-center">
										<h4
											className="text-center gap-[10px] text-[18px] mt-[16px] 5xl:mt-[24px] leading-[24px] lg:text-[20px] 5xl:text-[24px] font-[600]"
											style={{
												color: profileDetails.darkMode === 0 ? "#000" : "#fff",
											}}
										>
											{profile?.title}
										</h4>
									</div>
									<div>
										<div className="gap-[10px] flex justify-center">
											<p
												className="text-[14px] lg:text-[15px] 5xl:text-[20px] leading-[19.6px] 5xl:leading-[21.6px] mt-[8px] 5xl:mt-[16px] font-normal inline-block text-center"
												style={{ color: "#828282" }}
											>
												{profile?.description}
											</p>
										</div>
									</div>
									<div
										className="flex justify-center items-center text-center  gap-[24px] mt-[16px] flex-wrap"
										style={{ color: "#828282" }}
									>
										<span className="flex items-center text-[14px] leading-[20px] 5xl:text-[20px] max-w-[100%]">
											<img src={date} alt="date" className="mr-[4px]" />
											Joined {moment(profile?.createdAt).format("MMM YYYY")}
										</span>
										<span
											className="flex items-center text-[14px] leading-[20px] 5xl:text-[20px] max-w-[100%]"
											style={{ color: "#828282" }}
										>
											<img src={friends} alt="friend" className="mr-[4px]" />
											<span
												style={{
													color: "#0070BB",
													fontWeight: "600",
													marginRight: "4px",
													fontSize: "14px",
													lineHeight: "20px",
												}}
											>
												{abbreviateNumber(profile?.friend_counts) || 0}
											</span>
											<span className="leading-[20px] lg:text-[14px] 5xl:text-[20px]">
												{profile?.friend_counts > 1
													? " Followers"
													: " Follower"}
											</span>
										</span>
									</div>
									<div className="lg:hidden flex justify-center items-center responsive-icons mt-[16px] mb-[1rem] gap-[10px]">
										{socialLinks?.length > 0 && (
											<>
												{socialLinks?.length <= 6 ? (
													<>
														<div className="w-[30px] mr-[0px]">
															<Link to={{ pathname: `/` }} onClick={handleDialogOpen}>
																<img src={hash} alt="hashtag" />
															</Link>
														</div>
														{socialLinks?.map((icon) => {
															let data = icon?.data?.[0] || {};
															if (icon?.data?.length > 1) {
																data = {
																	_id: icon?.data?.[0]?._id,
																	logo: icon?.data?.[0]?.logo,
																	url: icon?.data,
																};
															}
															return (
																<div
																	key={data?._id}
																	className="flex justify-center items-center mx-1"
																	style={{ width: "30px" }}
																>
																	{typeof data?.url === "string" && (
																		<Link to={data?.url} target="_blank">
																			<img
																				src={data?.logo}
																				alt={icon?.name}
																				className="h-auto w-[30px]"
																			/>
																		</Link>
																	)}
																	{Array.isArray(data?.url) && (
																		<Fragment>
																			<button
																				onClick={(event) => {
																					if (openPopoverId === data?._id) {
																						setOpenPopoverId(null);
																						setAnchorEl(null);
																					} else {
																						setOpenPopoverId(data?._id);
																						setAnchorEl(event.currentTarget);
																					}
																				}}
																				style={{
																					background: "none",
																					border: "none",
																					padding: 0,
																					cursor: "pointer",
																				}}
																			>
																				<img
																					src={data?.logo}
																					alt={icon?.name}
																					className="h-auto w-[30px]"
																				/>
																			</button>

																			{openPopoverId === data?._id && (
																				<ClickAwayListener
																					onClickAway={(event) => {
																						if (
																							!event.target.closest('.popover-content') &&
																							!event.target.closest('button')
																						) {
																							setOpenPopoverId(null);
																							setAnchorEl(null);
																						}
																					}}
																				>
																					<HoverPopover
																						anchorEl={anchorEl}
																						open={openPopoverId === data?._id}
																						onClose={() => {
																							setOpenPopoverId(null);
																							setAnchorEl(null);
																						}}
																						anchorOrigin={{
																							vertical: "bottom",
																							horizontal: "center",
																						}}
																						transformOrigin={{
																							vertical: "top",
																							horizontal: "center",
																						}}
																						sx={{ marginTop: "5px" }}
																					>
																						<div className="popover-content">
																							{data?.url?.map((item, i) => (
																								<Fragment key={`${data?._id}-${i}`}>
																									<a
																										href={item?.url}
																										target="_blank"
																										rel="noopener noreferrer"
																										onClick={(e) => {
																											e.stopPropagation();
																											setOpenPopoverId(null);
																											window.open(item?.url, '_blank');
																										}}
																										style={{
																											display: "flex",
																											alignItems: "center",
																											backgroundColor: "#f5f5f7",
																											color: "#000",
																											textDecoration: "none",
																										}}
																									>
																										<img
																											className="w-[26px] h-[26px] mx-[5px]"
																											src={item?.logo}
																											alt={item?.name}
																										/>
																										<Typography
																											className="break-paras"
																											sx={{
																												padding: "5px",
																												width: "300px",
																												minWidth: "200px",
																												wordBreak: "break-word",
																												color: "#000",
																												backgroundColor: "#f5f5f9",
																											}}
																										>
																											{item?.title}
																										</Typography>
																									</a>
																									{i < data?.url?.length - 1 && (
																										<Divider sx={{ borderColor: "#cbcaca" }} />
																									)}
																								</Fragment>
																							))}
																						</div>
																					</HoverPopover>
																				</ClickAwayListener>
																			)}
																		</Fragment>
																	)}
																</div>
															);
														})}
													</>
												) : (
													<>
														<div className=" mr-[0px]">
															<Link to={{ pathname: `/` }}>
																<img
																	className="swipers-image"
																	src={hash}
																	alt="hashtag"
																/>
															</Link>
														</div>
														<Swiper
															slidesPerView="auto"
															spaceBetween={10}
															centeredSlides={false}
															className="social-icons-swiper"
														>
															{socialLinks?.map((icon) => {
																let data = icon?.data?.[0] || {};
																if (icon?.data?.length > 1) {
																	data = {
																		_id: icon?.data?.[0]?._id,
																		logo: icon?.data?.[0]?.logo,
																		url: icon?.data,
																	};
																}
																return (
																	<SwiperSlide
																		key={data?._id}
																		className="swiperslides"
																	>
																		<li
																			className="flex items-center mx-1"
																			style={{ width: "30px" }}
																		>
																			{typeof data?.url === "string" && (
																				<Link to={data?.url} target="_blank">
																					<img
																						src={data?.logo}
																						alt={icon?.name}
																						className="h-auto w-[30px]"
																					/>
																				</Link>
																			)}
																			{Array.isArray(data?.url) && (
																				<Fragment>
																					<button
																						onClick={(event) => {
																							if (openPopoverId === data?._id) {
																								setOpenPopoverId(null);
																								setAnchorEl(null);
																							} else {
																								setOpenPopoverId(data?._id);
																								setAnchorEl(event.currentTarget);
																							}
																						}}
																						style={{
																							background: "none",
																							border: "none",
																							padding: 0,
																							cursor: "pointer",
																						}}
																					>
																						<img
																							src={data?.logo}
																							alt={icon?.name}
																							className="h-auto w-[30px]"
																						/>
																					</button>

																					{openPopoverId === data?._id && (
																						<ClickAwayListener
																							onClickAway={(event) => {
																								if (
																									!event.target.closest('.popover-content') &&
																									!event.target.closest('button')
																								) {
																									setOpenPopoverId(null);
																									setAnchorEl(null);
																								}
																							}}
																						>
																							<HoverPopover
																								anchorEl={anchorEl}
																								open={openPopoverId === data?._id}
																								onClose={() => {
																									setOpenPopoverId(null);
																									setAnchorEl(null);
																								}}
																								anchorOrigin={{
																									vertical: "bottom",
																									horizontal: "center",
																								}}
																								transformOrigin={{
																									vertical: "top",
																									horizontal: "center",
																								}}
																								sx={{ marginTop: "5px" }}
																							>
																								<div className="popover-content">
																									{data?.url?.map((item, i) => (
																										<Fragment key={`${data?._id}-${i}`}>
																											<a
																												href={item?.url}
																												target="_blank"
																												rel="noopener noreferrer"
																												onClick={(e) => {
																													e.stopPropagation();
																													setOpenPopoverId(null);
																													window.open(item?.url, '_blank');
																												}}
																												style={{
																													display: "flex",
																													alignItems: "center",
																													backgroundColor: "#f5f5f7",
																													color: "#000",
																													textDecoration: "none",
																												}}
																											>
																												<img
																													className="w-[26px] h-[26px] mx-[5px]"
																													src={item?.logo}
																													alt={item?.name}
																												/>
																												<Typography
																													className="break-paras"
																													sx={{
																														padding: "5px",
																														width: "300px",
																														minWidth: "200px",
																														wordBreak: "break-word",
																														color: "#000",
																														backgroundColor: "#f5f5f9",
																													}}
																												>
																													{item?.title}
																												</Typography>
																											</a>
																											{i < data?.url?.length - 1 && (
																												<Divider sx={{ borderColor: "#cbcaca" }} />
																											)}
																										</Fragment>
																									))}
																								</div>
																							</HoverPopover>
																						</ClickAwayListener>
																					)}
																				</Fragment>
																			)}
																		</li>
																	</SwiperSlide>
																);
															})}
														</Swiper>
													</>
												)}
											</>
										)}
									</div>
									<div className="hidden lg:flex justify-center items-center mx-auto">
										{socialLinks?.length > 0 && (
											<ul className="mx-[10px] mt-0">
												<ul className="social-icons flex flex-wrap justify-center mt-[16px] items-baseline gap-[20px] md:flex-nowrap">
													<li className="w-[10%] md:w-[30px]">
														<Link to={{ pathname: `/` }}>
															<img
																src={hash1}
																alt="hashtag"
																className="w-full h-auto mt-[0.1rem]"
															/>
														</Link>
													</li>
													{socialLinks?.map((icon) => {
														let data = icon?.data?.[0] || {};
														if (icon?.data?.length > 1) {
															data = {
																_id: icon?.data?.[0]?._id,
																logo: icon?.data?.[0]?.logo,
																url: icon?.data,
															};
														}
														return (
															<li
																className="w-[10%] md:w-[30px]"
																key={data?._id}
															>
																{typeof data?.url === "string" && (
																	<Link to={data?.url} target="_blank">
																		<img
																			src={data?.logo}
																			alt={icon?.name}
																			className="w-full h-auto mt-[0.1rem]"
																		/>
																	</Link>
																)}
																{Array.isArray(data?.url) && (
																	<Fragment>
																		<button
																			onClick={(event) => {
																				if (openPopoverId === data?._id) {
																					setOpenPopoverId(null);
																					setAnchorEl(null);
																				} else {
																					setOpenPopoverId(data?._id);
																					setAnchorEl(event.currentTarget);
																				}
																			}}
																			style={{
																				background: "none",
																				border: "none",
																				padding: 0,
																				cursor: "pointer",
																			}}
																		>
																			<img
																				src={data?.logo}
																				alt={icon?.name}
																				className="h-auto w-[30px]"
																			/>
																		</button>

																		{openPopoverId === data?._id && (
																			<ClickAwayListener
																				onClickAway={(event) => {
																					if (
																						!event.target.closest('.popover-content') &&
																						!event.target.closest('button')
																					) {
																						setOpenPopoverId(null);
																						setAnchorEl(null);
																					}
																				}}
																			>
																				<HoverPopover
																					anchorEl={anchorEl}
																					open={openPopoverId === data?._id}
																					onClose={() => {
																						setOpenPopoverId(null);
																						setAnchorEl(null);
																					}}
																					anchorOrigin={{
																						vertical: "bottom",
																						horizontal: "center",
																					}}
																					transformOrigin={{
																						vertical: "top",
																						horizontal: "center",
																					}}
																					sx={{ marginTop: "5px" }}
																				>
																					<div className="popover-content">
																						{data?.url?.map((item, i) => (
																							<Fragment key={`${data?._id}-${i}`}>
																								<a
																									href={item?.url}
																									target="_blank"
																									rel="noopener noreferrer"
																									onClick={(e) => {
																										e.stopPropagation();
																										setOpenPopoverId(null);
																										window.open(item?.url, '_blank');
																									}}
																									style={{
																										display: "flex",
																										alignItems: "center",
																										backgroundColor: "#f5f5f7",
																										color: "#000",
																										textDecoration: "none",
																									}}
																								>
																									<img
																										className="w-[26px] h-[26px] mx-[5px]"
																										src={item?.logo}
																										alt={item?.name}
																									/>
																									<Typography
																										className="break-paras"
																										sx={{
																											padding: "5px",
																											width: "300px",
																											minWidth: "200px",
																											wordBreak: "break-word",
																											color: "#000",
																											backgroundColor: "#f5f5f9",
																										}}
																									>
																										{item?.title}
																									</Typography>
																								</a>
																								{i < data?.url?.length - 1 && (
																									<Divider sx={{ borderColor: "#cbcaca" }} />
																								)}
																							</Fragment>
																						))}
																					</div>
																				</HoverPopover>
																			</ClickAwayListener>
																		)}
																	</Fragment>
																)}
															</li>
														);
													})}
												</ul>
											</ul>
										)}
									</div>
									{profile?.additional_information && (
										<div className="mx-[5px] mt-[16px] 5xl:mt-[24px]">
											<div
												className="p-[15px] bg-[#fff] text-[14px] lg:text-[15px] 5xl:text-[21px] whitespace-break-spaces rounded-[10px]"
												style={{
													backgroundColor:
														profileDetails.darkMode === 0 ? "#fff" : "#202020",
												}}
											>
												<h2
													className="text-[15px] lg:text-[16px] 5xl:text-[22px] font-[700] mb-[8px] 5xl:mb-[16px]"
													style={{
														color:
															profileDetails.darkMode === 0
																? "#222222"
																: "#fff",
													}}
												>
													Quick Bites
												</h2>

												<p
													style={{
														color:
															profileDetails.darkMode === 0
																? "#828282"
																: "#DADCDE",
													}}
												>
													{profile?.additional_information}
												</p>
											</div>
										</div>
									)}
									{profile?.websites?.length > 0 && (
										<div className="mx-[5px] gap-[8px] mt-[24px] 5xl:mt-[48px]">
											<h3
												className="text-[15px] 5xl:text-[21px] mb-[7px] 5xl:mb-[14px] font-[700]"
												style={{
													color:
														profileDetails.darkMode === 0 ? "#000" : "#fff",
												}}
											>
												{`${profile.username}'s`} Microsite
											</h3>
											{profile.websites.map((item, i) => {
												return (
													<div
														className="flex flex-nowrap items-center px-[10px] py-[20px] rounded-[10px] mb-[8px]"
														style={{
															backgroundColor:
																profileDetails.darkMode === 0
																	? "#fff"
																	: "#202020",
														}}
														key={item?._id}
													>
														<div className="flex items-center mr-[10px] h-[100%] md:h-[100%]">
															<img
																className="absolute w-[50px] h-[50px] md:w-[50px] md:h-[50px] border-[2px] border-[#0070BB] rounded-[50px] object-cover"
																src={
																	item?.websit_logo ||
																	"../../images/my-micro-img.png"
																}
																alt="my-micro-img.png"
															/>
														</div>
														<div className="ml-[55px] relative flex flex-col leading-[18px]">
															<p
																className="text-[15px] lg:text-[16px] 5xl:text-[22px] font-[500] pb-[5px] 5xl:pb-[10px]"
																style={{
																	color:
																		profileDetails.darkMode === 0
																			? "#121111"
																			: "#fff",
																}}
															>
																{item?.web_title || ""}
															</p>
															<p
																className="text-[13px] lg:text-[14px] 5xl:text-[19px] leading-[15px] break-para"
																style={{ color: "#828282" }}
															>
																{item?.web_description || ""}
															</p>
														</div>
													</div>
												);
											})}
										</div>
									)}
								</div>
							</div>
							<div className="w-full flex items-center justify-center mt-[25px] 5xl:mt-[50px]">
								<button
									className="bg-[#0068af] py-4 w-full text-[14px] 5xl:text-[19px] font-semibold capitalize"
									style={{ color: "#fff" }}
									onClick={homepageURL}
								>
									Join AeeDee
								</button>
							</div>
						</div>
					</div>
				</div>
			)}
		</Fragment>
	);
};
export default Profile;

Profile.propTypes = {
	settingsDetails: PropTypes.object
};