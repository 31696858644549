import React, { useMemo, useState, useEffect } from 'react'
import { FormHelperText, Box, FormControl } from '@mui/material';
import { openSnackbar } from '../store/snackbar';
import { LoadingButton } from '@mui/lab';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { ContactSchema } from '../utils/validation-schema'
import { dispatch, useSelector } from '../store';
import { commonAPICall, commonClearMessages } from '../store/common';
import Background from "../assets/img/frame-bg.png";
import map from '../assets/img/Basemap image.png';
import marker from '../assets/img/Spotlight Marker.png';
import PopupContact from './Popup-contact';
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import './index.css';
const Contacts = () => {
    const [verifyModalPopup, setVerifyModalPopup] = useState(false);
    const [disabledButton, setDisabledButton] = useState(false);
    const handleClose = () => {
        setVerifyModalPopup(false);
    };
    const { commonErrorMessage, commonSuccessMessage, commonRespStatus, commonActionState, commonButtonState } = useSelector((state) => state.common);
    const defaultValues = useMemo(() => ({
        first_name: '',
        last_name: '',
        phone: '',
        email: '',
        message: ""
    }), []);

    const methods = useForm({ resolver: yupResolver(ContactSchema), defaultValues });
    const { reset, handleSubmit, control } = methods;
    const onSubmit = handleSubmit((data = {}) => {
        if (!data.email) {
            dispatch(
                openSnackbar({
                    open: true,
                    message: `Email is required`,
                    variant: 'warning',
                    alert: {
                        color: 'warning'
                    },
                    close: true
                })
            );
            return;
        }
        data.actionType = "website";
        setDisabledButton(true)
        dispatch(commonAPICall(`user/site/save-contact`, `POST`, `save-contact`, data))
    });

    useEffect(() => {
        dispatch(commonClearMessages());
    }, [])

    useEffect(() => {
        if (commonSuccessMessage !== null && commonRespStatus === 1) {
            if (commonActionState === 'save-contact') {
                reset();
                setVerifyModalPopup(true);
                dispatch(
                    openSnackbar({
                        open: true,
                        message: commonSuccessMessage,
                        variant: 'filled',
                        alert: {
                            color: 'primary'
                        },
                        close: true
                    })
                );
                dispatch(commonClearMessages());
            }
        } else if (commonErrorMessage !== null) {
            dispatch(
                openSnackbar({
                    open: true,
                    message: commonErrorMessage,
                    variant: 'alert',
                    alert: {
                        color: 'error'
                    },
                    close: true
                })
            );
            dispatch(commonClearMessages());
        }
    }, [commonErrorMessage, commonSuccessMessage, commonRespStatus, commonActionState, commonButtonState, reset])

    return (
        <div className="pt-[65px] min-h-screen bg-[#f3f2f7]">
            <div className="relative">
                <div className="absolute custom-bg-size bg-no-repeat custom-bg-position inset-0 opacity-30" style={{ backgroundImage: `url(${Background})` }}>  </div>                <div className="relative w-full h-auto flex justify-center">
                    <div className="container 2xl:max-w-[1280px] 3xl:max-w-[1536px]  static bg-[#000] opacity-[0.70] p-[10px] md:p-[50px] md:w-[90%] lg:w-[96%] lg:mt-[-0.15rem] 4xl:mt-[-0.11rem] mt-[0.7rem] rounded-b-[15px] min-h-[500px]">
                        <div className='relative z-10'>
                            <div className='mb-8 text-center'>
                                <div className='text-[40px] md:text-[80px]'>Contact Us</div>
                                <div className='text-[16px] md:text-[24px] text-[#f6e2b9]'>Any question or remarks? Just write us a message!</div>
                            </div>
                            <div className="flex items-center justify-center min-h-screen">
                                <div className="w-full max-w-[1300px] border-0 md:border-[1px] border-[#999999] rounded-[60px] p-4 md:p-8">
                                    <div className='grid grid-cols-1 md:justify-center lg:grid-cols-2 items-center gap-4 md:gap-10'>
                                        <div className='w-full md:w-[500px] lg:w-[400px] mx-0 md:mx-[2rem]'>
                                            <h2 className='text-[30px] md:text-[60px] font-bold text-center lg:text-left bg-gradient-to-r from-[#7A7A7E] to-[#FFFFFF] bg-clip-text text-transparent'>Get in touch</h2>
                                            <p className='mx-auto text-[16px] md:text-[18px] text-center lg:text-left'>We collaborate with thousands of creators, entrepreneurs and complete legends.</p>
                                            <hr className="w-full md:w-[350px] h-[1px] my-4 bg-gray-100 border-0 rounded md:my-4 md:mx-[4rem] lg:mx-0" />
                                            <div className='flex flex-col gap-4 md:flex md:flex-col md:justify-center md:mx-[8rem] md:text-left lg:flex lg:justify-normal lg:items-center lg:ml-[-1rem] lg:mr-[2rem]'>
                                                <span>Headquarters</span>
                                                <span>Bishop Ranch 3, 2603 Camino Ramon, Suite 200</span>
                                                <span>San Ramon, California 94583</span>
                                                <span>1-888-315-0030 |  1-408-213-8878</span>
                                                <span>info@AeeDee.com</span>
                                            </div>
                                        </div>
                                        <div>
                                            <form className="max-w-xl mx-auto mt-4" onSubmit={handleSubmit(onSubmit)} autoComplete='off'>
                                                <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                                                    <div className="relative z-0 mb-5 group">
                                                        <Controller
                                                            name="first_name"
                                                            control={control}
                                                            render={({ field, fieldState: { error } }) => (
                                                                <>
                                                                    <input type="text"
                                                                        {...field}
                                                                        className="block py-2.5 px-0 w-full text-md text-[#fff] bg-transparent border-0 border-b-2 border-gray-300 appearance-none  focus:outline-none focus:ring-0 focus:border-[#fff] peer"
                                                                    />
                                                                    {error?.message && <FormHelperText error>{error?.message}</FormHelperText>}
                                                                </>
                                                            )}
                                                        />
                                                        <label htmlFor="first_name"
                                                            className="peer-focus:font-medium absolute text-md text-gray-500  duration-300 transform -translate-y-6 scale-75 top-3 z-0 origin-[0] peer-focus:left-0 peer-focus:text-[#fff] peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">
                                                            First name
                                                        </label>
                                                    </div>
                                                    <div className="relative z-0 mb-5 group">
                                                        <Controller
                                                            name="last_name"
                                                            control={control}
                                                            render={({ field, fieldState: { error } }) => (
                                                                <>
                                                                    <input type="text"
                                                                        {...field}
                                                                        className="block py-2.5 px-0 w-full text-md text-[#fff] bg-transparent border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-[#fff] peer"
                                                                    />
                                                                    {error?.message && <FormHelperText error>{error?.message}</FormHelperText>}
                                                                </>
                                                            )}
                                                        />
                                                        <label htmlFor="last_name"
                                                            className="peer-focus:font-medium absolute text-md text-gray-500  duration-300 transform -translate-y-6 scale-75 top-3 z-0 origin-[0] peer-focus:left-0 peer-focus:text-[#fff]  peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">
                                                            Last name
                                                        </label>
                                                    </div>
                                                </div>
                                                <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                                                    {/* Email Field */}
                                                    <div className="relative z-0 mb-5 group">
                                                        <Controller
                                                            name="email"
                                                            control={control}
                                                            render={({ field, fieldState: { error } }) => (
                                                                <>
                                                                    <input
                                                                        type="text"
                                                                        {...field}
                                                                        className="block py-2.5 px-0 w-full text-md text-[#fff] bg-transparent border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-[#fff] peer z-[1]" // Ensure email has lower z-index
                                                                    />
                                                                    {error?.message && <FormHelperText error>{error?.message}</FormHelperText>}
                                                                </>
                                                            )}
                                                        />
                                                        <label htmlFor="email"
                                                            className="peer-focus:font-medium absolute text-md text-gray-500 duration-300 transform -translate-y-6 scale-75 top-3 z-0 origin-[0] peer-focus:left-0 peer-focus:text-[#fff] peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">
                                                            Email address
                                                        </label>
                                                    </div>
                                                    {/* Phone Field */}
                                                    <div className="relative z-[100] mb-5 group"> {/* Set higher z-index */}
                                                        <FormControl fullWidth>
                                                            <Controller
                                                                control={control}
                                                                name="phone"
                                                                render={({ field, fieldState }) => (
                                                                    <Box className="phone-country-contact relative z-[101]"> {/* Higher z-index for PhoneInput */}
                                                                        <PhoneInput
                                                                            inputProps={{
                                                                                name: "phone",
                                                                                required: true,
                                                                                autoFocus: true,
                                                                            }}
                                                                            country={"us"}
                                                                            value={field?.value}
                                                                            countryCodeEditable={false}
                                                                            enableSearch={true}
                                                                            disableSearchIcon={true}
                                                                            onChange={(value, country, e, formattedValue) => {
                                                                                field?.onChange(formattedValue)
                                                                            }}
                                                                            containerStyle={{ position: 'relative', zIndex: 50 }}   // Ensure dropdown container is above
                                                                            dropdownStyle={{ zIndex: 110 }}   // Ensure dropdown is above other elements
                                                                            className="block py-2.5 px-0 w-full text-md text-[#fff] bg-transparent bg-opacity-1 border-0 border-b-2 border-gray-300 appearance-none  focus:outline-none focus:ring-0 focus:border-[#fff] peer z-[100]"  // Dropdown with higher z-index
                                                                            searchClass="phone-input-search-contact"
                                                                            inputClass="phone-dropdown-contact"
                                                                        />
                                                                        {fieldState.invalid ? (<FormHelperText error>{fieldState?.error?.message}</FormHelperText>) : null}
                                                                    </Box>
                                                                )}
                                                            />
                                                            <label htmlFor="phone"
                                                                className="peer-focus:font-medium absolute text-md text-gray-500  duration-300 transform -translate-y-6 scale-75 top-3 z-[99999] origin-[0] peer-focus:left-0 peer-focus:text-[#fff]  peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">
                                                                Phone number
                                                            </label>
                                                        </FormControl>
                                                    </div>
                                                </div>
                                                {/* Message Field */}
                                                <div className="grid grid-cols-1 md:grid-cols-1 mt-4">
                                                    <div className="relative z-0 mb-5 group">
                                                        <Controller
                                                            name="message"
                                                            control={control}
                                                            render={({ field, fieldState: { error } }) => (
                                                                <>
                                                                    <input
                                                                        type="text"
                                                                        {...field}
                                                                        className="block py-2.5 px-0 w-full text-md text-[#fff] bg-transparent border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-[#fff] peer z-[1]"  // Lower z-index for message input
                                                                    />
                                                                    {error?.message && <FormHelperText error>{error?.message}</FormHelperText>}
                                                                </>
                                                            )}
                                                        />
                                                        <label htmlFor="message"
                                                            className="peer-focus:font-medium absolute text-md text-gray-500 duration-300 transform -translate-y-6 scale-75 top-3 z-0 origin-[0] peer-focus:left-0 peer-focus:text-[#fff] peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">
                                                            Message
                                                        </label>
                                                    </div>
                                                </div>
                                                {/* Submit Button */}
                                                <div>
                                                    <LoadingButton
                                                        variant="contained"
                                                        type="submit"
                                                        loading={disabledButton}
                                                        sx={{
                                                            width: '100%',
                                                            maxWidth: '200px',
                                                            display: 'flex',
                                                            justifyContent: 'center',
                                                            alignItems: 'center',
                                                            marginTop: '3rem',
                                                            backgroundColor: '#1976D1',
                                                            color: '#fff',
                                                            borderRadius: '32px'
                                                        }}
                                                    >
                                                        Submit
                                                    </LoadingButton>
                                                    {verifyModalPopup && (
                                                        <PopupContact verifyModalShow={verifyModalPopup} handleClose={handleClose} />
                                                    )}
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                    <div className="hidden md:block m-[2rem] relative">
                                        <img src={map} alt="map" className="md:w-full md:h-auto w-[400px] h-[300px]" />
                                        <img src={marker} alt="marker" className="absolute" style={{ top: '50%', left: '50%', transform: 'translate(-50%, -100%)' }} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Contacts;
