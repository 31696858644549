import {React} from 'react';
import Bgimage from '../assets/img/advertiser-bg.png';
import Background from "../assets/img/frame-bg.png";
import logo from '../assets/img/Group1.svg';
import video from '../assets/img/Rectangleframe.png';
import vector from '../assets/img/vector.png';
import google from '../assets/img/Badge.png';
import app from '../assets/img/Group.png';
import animation from '../assets/img/Animation.png';
import mouse from '../assets/img/mouse-click.png';
import './index.css';

const Advertisers = () => {


  return (
   
    <div className="pt-[62px] min-h-screen overflow-x-hidden bg-[#f3f2f7]">
    <div className="parent-container flex justify-end">
        <div className="bg-no-repeat custom-bg-position custom-adv-bg" 
             style={{ 
                 backgroundImage: `url(${Background})`, 
                 width: '100%', 
                 height: '100%', 
                 opacity: '0.10',
                 position: 'absolute',
                 top: '29%',
                 right:'0',
             }} 
        />
    </div>
    
    <div
  className="background-image md:h-[100vh] h-[30vh] "
  style={{
    backgroundImage: `url(${Bgimage})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    width: '100vw',
  }}
>   
 <div className="relative z-10 flex flex-col justify-center items-center md:min-h-screen xl:items-center h-auto px-4 sm:px-8 md:px-16 lg:px-24 xl:px-32">
    <h2 className="text-center w-full text-[20px] sm:text-[36px] md:text-[52px] lg:text-[67px] xl:text-[100px] font-semibold lg:mt-[4rem] mt-[5.5rem]">
    Advertise with us
    </h2>
    <p className="text-center w-full sm:w-[75%] md:w-[67%] lg:w-[50%] text-[10px] sm:text-[14px] md:text-[20px] lg:text-[22px] xl:text-[24px] 5xl:text-[38px] text-[#F4F4F4] mt-2 sm:mt-0.5 lg:mt-4">
    Lorem ipsum is a placeholder text commonly used to demonstrate the visual form of a document or a typeface without relying on meaningful content.     </p>
    
</div>


</div>
  <div className="relative w-full h-auto flex justify-center overflow-x-hidden">
          
          <div className="container 2xl:max-w-[1280px] 3xl:max-w-[1536px]  static bg-[#000] opacity-[1] px-[2px] md:p-[10px] md:w-[90%] lg:w-[97%] lg:mt-[0rem] pt-[1rem] rounded-b-[15px] min-h-[500px]">
          <div className="mx-4 sm:mx-6 md:mx-6 lg:mx-12 grid grid-cols-1 md:grid-cols-2 md:gap-[4rem] lg:gap-[5rem] xl:gap-4 2xl:gap-[4rem] relative h-auto min-h-[426px] px-4">
  <div className="flex flex-col justify-center items-center md:items-start text-center md:text-left">
    <h3 className="w-full tracking-widest text-[18px] sm:text-[22px] md:text-[20px] 5xl:text-[26px] font-semibold text-[#1172BA]">
    ADVERTISE WITH US
    </h3>
    <h2 className="w-full max-w-[713px] text-[20px] sm:text-[30px] md:text-[30px] lg:text-[40px] xl:text-[50px] 5xl:text-[60px] font-bold text-gradient mb-4">
    Why Advertise with Us?
    </h2>
    <p className="w-full max-w-full md:max-w-[570px] text-[12px] sm:text-[14px] md:text-[16px] 5xl:text-[24px] text-[#F4F4F4] font-normal">
    Whether you're looking to increase brand awareness, drive foot traffic, or boost sales, we're here to support you every step of the way.    </p>
    <div className="flex justify-center md:justify-start w-full mt-6">
    <button
        type="submit"
        className="flex items-center justify-start md:flex md:justify-start md:items-center text-[#0D0D0D] bg-[#fff] hover:bg-[#808080] font-medium rounded-[58px] text-[12px] lg:text-[21px] lg:w-auto w-auto h-auto px-5 py-0.5 text-center ">
        <img src={mouse} alt="mouse-click" className="mr-2"/>Watch the demo
    </button>
</div>



  </div>
  <div className="relative w-full max-w-full md:max-w-[60rem] lg:max-w-[80rem] 5xl:max-w-[50rem] h-auto flex items-center justify-center">
  <img
    src={video}
    alt="video"
    className="inset-0 w-auto h-auto object-contain mb-[2rem] mt-[4rem] sm:mt-[6rem] md:mt-[3rem] lg:mt-[1rem] lg:ml-[-4rem]"
  />
</div>

</div>



            <div className="mx-auto grid grid-cols-1 md:grid-cols-1 lg:grid-cols-2 gap-4 md:mt-[1rem] lg:mt-[0rem]">
              <div className="flex flex-col items-center md:items-start justify-center px-[30px]">
                <img src={logo} alt="logo" className="mb-4 w-24 h-24 md:w-32 md:h-32" />
                <h3 className="text-center md:text-left w-full text-[20px] sm:text-[30px] md:text-[50px] lg:text-[60px] font-semibold text-gradient">
                Unlock Opportunities with AeeDee
                </h3>
              </div>

              <div>
                <div className="mx-auto px-[10px] grid grid-cols-1 md:grid-cols-2 gap-8">
                  <div className="flex flex-col items-center md:items-start mb-4">
                    <div className="flex items-center mb-2">
                      <span className="text-[30px] sm:text-[35px] md:text-[40px] font-semibold text-[#222222] mr-4">01</span>
                      <h4 className="w-full max-w-[329px] text-[18px] sm:text-[20px] md:text-[22px] lg:text-[26px] 4xl:text-[28px] text-[#fff]">Register Account</h4>
                    </div>
                    <p className="w-full max-w-[570px] text-[14px] sm:text-[16px] md:text-[16px] 4xl:text-[19px] text-[#F4F4F4] font-medium text-center md:text-left">
                    Lorem ipsum is a placeholder text commonly used to demonstrate the visual form of a document or a typeface without relying on meaningful content.                    </p>
                  </div>
                  <div className="flex flex-col items-center md:items-start mb-4">
                    <div className="flex items-center mb-2">
                      <span className="text-[30px] sm:text-[35px] md:text-[40px] font-semibold text-[#222222] mr-4">02</span>
                      <h4 className="w-full max-w-[329px] text-[18px] sm:text-[20px] md:text-[22px] lg:text-[26px] 4xl:text-[28px] text-[#fff]">Launch Your Campaign</h4>
                    </div>
                    <p className="w-full max-w-[570px] text-[14px] sm:text-[16px] md:text-[16px] 4xl:text-[19px] text-[#F4F4F4] font-medium text-center md:text-left">
                    Lorem ipsum is a placeholder text commonly used to demonstrate the visual form of a document or a typeface without relying on meaningful content.                    </p>
                  </div>
                  <div className="flex flex-col items-center md:items-start mb-4">
                    <div className="flex items-center mb-2">
                      <span className="text-[30px] sm:text-[35px] md:text-[40px] font-semibold text-[#222222] mr-4">03</span>
                      <h4 className="w-full max-w-[329px] text-[18px] sm:text-[20px] md:text-[22px] lg:text-[26px] 4xl:text-[28px] text-[#fff]">Monitor Performance</h4>
                    </div>
                    <p className="w-full max-w-[570px] text-[14px] sm:text-[16px] md:text-[16px] 4xl:text-[19px] text-[#F4F4F4] font-medium text-center md:text-left">
                      Lorem ipsum is a placeholder text commonly used to demonstrate the visual form of a document or a typeface without relying on meaningful content.
                    </p>
                  </div>
                  <div className="flex flex-col items-center md:items-start mb-4">
                    <div className="flex items-center mb-2">
                      <span className="text-[30px] sm:text-[35px] md:text-[40px] font-semibold text-[#222222] mr-4">04</span>
                      <h4 className="w-full max-w-[329px] text-[18px] sm:text-[20px] md:text-[22px] lg:text-[26px] 4xl:text-[28px] text-[#fff]">Optimize Your Strategy</h4>
                    </div>
                    <p className="w-full max-w-[570px] text-[14px] sm:text-[16px] md:text-[16px] 4xl:text-[19px] text-[#F4F4F4] font-medium text-center md:text-left">
                      Lorem ipsum is a placeholder text commonly used to demonstrate the visual form of a document or a typeface without relying on meaningful content.
                    </p>
                  </div>
                  <div className="flex flex-col items-center md:items-start mb-4">
                    <div className="flex items-center mb-2">
                      <span className="text-[30px] sm:text-[35px] md:text-[40px] font-semibold text-[#222222] mr-4">05</span>
                      <h4 className="w-full max-w-[329px] text-[18px] sm:text-[20px] md:text-[22px] lg:text-[26px] 4xl:text-[28px] text-[#fff]">Grow Your Business</h4>
                    </div>
                    <p className="w-full max-w-[570px] text-[14px] sm:text-[16px] md:text-[16px] 4xl:text-[19px] text-[#F4F4F4] font-medium text-center md:text-left">
                      Lorem ipsum is a placeholder text commonly used to demonstrate the visual form of a document or a typeface without relying on meaningful content.
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className="relative flex flex-col justify-center items-center px-4 py-8 lg:mt-[3rem] md:mt-[2rem] lg:mb-[10rem] sm:mb-[0rem]">
  <div className="relative flex flex-col justify-center items-center w-full max-w-screen-lg border rounded-2xl p-2 z-40 bg-[#000]">
    <div className="grid grid-cols-1 md:grid-cols-2 gap-6 items-center md:mt-2">
      <div className="p-4 lg:pl-[4rem] flex flex-col items-center md:items-start">
        <h2 className="w-full max-w-[400px] 4xl:max-w-[450px] text-[24px] sm:text-[30px] md:text-[36px] lg:text-[40px] xl:text-[48px] 5xl:text-[54px] text-gradient font-semibold text-center md:text-left">
        Get AeeDee
        & Start Advertising
        </h2>
        <p className="text-[12px] sm:text-[14px] md:text-[16px] lg:text-[22px] 5xl:text-[26px] font-medium text-[#F4F4F4] text-center md:text-left mt-4">
          Download AeeDee Now! Become a verified user and accept payments as content creators.
        </p>
        <div className="flex space-x-4 mt-4">
          <img src={google} alt="google" className="w-[80px] sm:w-[80px] md:w-[100px] lg:w-[120px] 5xl:w-[220px] h-auto object-contain" />
          <img src={app} alt="app" className="w-[80px] sm:w-[80px] md:w-[100px] lg:w-[120px] 5xl:w-[220px] h-auto object-contain" />
        </div>
      </div>
      <div className="flex justify-center md:justify-end">
        <img src={vector} alt="vector" className="w-[220px] h-[180px] sm:w-[250px] sm:h-[200px] md:w-[300px] md:h-[250px] lg:w-[350px] lg:h-[300px]" />
      </div>
    </div>
  </div>
  <img
    src={animation}
    alt="animation"
    className="hidden lg:block lg:absolute md:right-[-65px] md:bottom-[-7px] lg:right-[-43px] lg:bottom-[-43px] 2xl:right-[25px] 2xl:bottom-[-54px] 3xl:right-[142px] 3xl:bottom-[-54px] 4xl:right-[106px] 4xl:bottom-[-48px] 5xl:right-[66px] 5xl:bottom-[-9px] md:w-[430px] md:h-[430px] lg:w-[500px] lg:h-[500px] 5xl:w-[550px] 5xl:h-[550px] animate-rotateAnimation z-0 scroll-mx-0"
  />
</div>




          </div>
        </div>
      </div>

  )
}

export default Advertisers