import React from 'react';
import { Link } from 'react-router-dom';
import logo from '../../assets/img/White_BG.svg';
import arrow from '../../assets/img/Frame.svg';
import facebook from '../../assets/img/colored-face.svg';
import twitter from '../../assets/img/colored-x.svg';
import linkedin from '../../assets/img/colored-linked.svg';
import instagram from '../../assets/img/colored-insta.svg';
import hashtag from '../../assets/img/colored-hash.svg';

const currentYear = new Date().getFullYear();

const NewFooter = () => {
	return (
		<footer className="py-[15px] bg-[#f3f2f7]">
			<div className="mx-auto container 2xl:max-w-[1280px] 3xl:max-w-[1500px] lg:p-[0rem] p-4 lg:pt-8">
				<div className="lg:grid lg:grid-cols-2 lg:gap-8">

					<div className="mb-6 md:mb-0 lg:col-span-1">
						<Link to="/" className='flex justify-center lg:justify-normal'>
							<img src={logo} className="h-12 mb-3" alt="Logo" />
						</Link>
						<div className="text-[24px] md:text-[35px] text-[#000] text-center lg:text-left lg:w-[600px] w-full" style={{ fontFamily: 'Urbanist, sans-serif', fontWeight: 500, lineHeight: '1.2', letterSpacing: '-1.79px' }}>
							Join us in shaping the future of AI as it evolves into a reality
						</div>
						<div className="flex flex-col md:flex-row md:justify-center lg:justify-start gap-4 mt-4 ">
							<button type="button" className="text-[#000] justify-center lg:justify-start hover:text-white border border-gray-800 hover:bg-gray-400 focus:ring-1 focus:outline-none focus:ring-gray-300 font-medium rounded-[56px] text-sm px-5 py-2.5  flex items-center space-x-2">
								<span>Work with us</span>
								<img src={arrow} alt="arrow" />
							</button>
							<button type="button" className="text-[#000] justify-center lg:justify-start hover:text-white border border-gray-800 hover:bg-gray-400 focus:ring-1 focus:outline-none focus:ring-gray-300 font-medium rounded-[56px] text-sm px-5 py-2.5  flex items-center space-x-2">
								<span>Join the team</span>
								<img src={arrow} alt="arrow" />
							</button>
						</div>
					</div>
					<div className="lg:col-span-2 lg:col-start-2">
						<div className="lg:grid lg:grid-cols-2 lg:gap-[3rem] lg:text-left grid grid-cols-1 gap-4 md:grid md:grid-cols-1 md:justify-items-center  md:mt-4 lg:ml-[6rem] xl:ml-[0rem]">
							<div>
								<ul className="text-[#000]  font-medium text-center lg:text-left">
									<li><Link to="/pages/privacy-policy" className="hover:underline">Privacy Policy</Link></li>
									<li><Link to="/pages/terms-and-conditions" className="hover:underline">Terms and Condition</Link></li>
									<li><Link to="/pages/partners" className="hover:underline">Satellite Partner</Link></li>
									<li><Link to="/market-research" className="hover:underline">Market Research</Link></li>
								</ul>
							</div>
							<div>
								<ul className="text-[#000]  font-medium text-center lg:text-left" >
									<li><Link to="/pages/about-us" className="hover:underline">About us</Link></li>
									<li><Link to="/contact-us" className="hover:underline">Contact us</Link></li>
									<li><Link to="/" className="hover:underline">Careers</Link></li>
									<li><Link to="/" className="hover:underline">Invest in us</Link></li>

								</ul>

							</div>
							<div className='lg:mt-[-1rem] mt-4 md:ml-[2rem] lg:ml-[0rem] xl:ml-[5rem]'>
								<ul className="text-[#000]  font-medium lg:flex lg:justify-start space-x-4 flex justify-center ">
									<li><Link to="/" ><img className="w-[38px] mt-[-3px] transition-transform duration-300 ease-in-out hover:rotate-[360deg]"
										src={hashtag} alt="hashtag" /></Link>
									</li>
									<li><Link to="https://www.youtube.com/" target="_blank"><img className="w-[35px] transition-transform duration-300 ease-in-out  hover:rotate-[360deg]"
										src={facebook} alt="youtube" /></Link>
									</li>
									<li><Link to="https://www.instagram.com/" target="_blank"><img className="w-[35px] transition-transform duration-300 ease-in-out  hover:rotate-[360deg]"
										src={instagram} alt="instagram" /></Link>
									</li>
									<li><Link to="https://www.linkedin.com/" target="_blank"><img className="w-[35px] transition-transform duration-300 ease-in-out  hover:rotate-[360deg]"
										src={linkedin} alt="linkedin" /></Link>
									</li>
									<li><Link to="https://x.com/" target="_blank"><img className="w-[35px] transition-transform duration-300 ease-in-out  hover:rotate-[360deg]"
										src={twitter} alt="twitter" /></Link>
									</li>

								</ul>
							</div>
						</div>
					</div>

				</div>
			</div>
			<footer className="pt-[15px] pb-[10px] bg-[#f3f2f7]">
				<div className="mx-auto px-4">
					<hr className="h-[0.2px] mb-4 bg-[#cdc6c6] border-0 " />
					<div className="flex justify-center">
						<p className="text-sm text-[#000] text-center">
							Copyright © {currentYear} AeeDee®. All rights reserved.
						</p>
					</div>
				</div>
			</footer>
		</footer>
	);
};

export default NewFooter;
