import React, { useState, useRef, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import logo from '../../assets/img/Black_BG.svg';
import { Button } from '@mui/material';
import './index.css';

const Header = () => {
  const [menuOpen, setMenuOpen] = useState(false);
  const location = useLocation();
  const menuRef = useRef(null);
  const buttonRef = useRef(null);

  const toggleMenu = () => {
    setMenuOpen((prevState) => !prevState);
  };


  useEffect(() => {
    const handleClickOutside = (event) => {

      if (
        menuRef.current &&
        !menuRef.current.contains(event.target) &&
        buttonRef.current &&
        !buttonRef.current.contains(event.target)
      ) {
        setMenuOpen(false);
      }
    };


    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [menuRef, buttonRef]);

  const handleMenuItemClick = () => {
    setMenuOpen(false);
  };
  return (
    <header className="py-[15px] fixed w-full z-[99] bg-[#000] opacity-[0.70]">
      <div className="w-full flex justify-center">
        <div className="2xl:max-w-[1280px] 3xl:max-w-[1536px] w-full px-4">
          <div className="flex flex-row justify-between items-center lg:hidden">
            <Link to="/" >
              <img src={logo} className="h-8" alt="Logo" />
            </Link>
            <Button onClick={toggleMenu}>
              {menuOpen ? (
                <svg width="34px" height="34px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path fillRule="evenodd" clipRule="evenodd" d="M12 10.586L17.95 4.636 19.364 6.05 13.414 12l5.95 5.95-1.414 1.414L12 13.414l-5.95 5.95-1.414-1.414L10.586 12 4.636 6.05 6.05 4.636 12 10.586z" fill="white" />
                </svg>
              ) : (
                <svg width="34px" height="34px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path fillRule="evenodd" clipRule="evenodd" d="M19.5 8.25H4.5V6.75H19.5V8.25Z" fill="#ffffff" />
                  <path fillRule="evenodd" clipRule="evenodd" d="M19.5 12.75H4.5V11.25H19.5V12.75Z" fill="#ffffff" />
                  <path fillRule="evenodd" clipRule="evenodd" d="M19.5 17.25H4.5V15.75H19.5V17.25Z" fill="#ffffff" />
                </svg>
              )}
            </Button>
          </div>
          <ul
            ref={menuRef}
            className={`menu_wrap p-[10px] lg:p-[0] bg-[#000] lg:bg-[transparent] lg:flex items-center justify-between ${menuOpen ? 'block' : 'hidden'} lg:block rounded-[4px]`}
          >
            <li>
              <Link
                className={`text-white p-[5px] text-[15px] block lg:inline-block underline-offset-8  ${location.pathname === '/' ? 'underline' : ''}`}
                to="/"
                onClick={handleMenuItemClick}
              >
                Home
              </Link>
            </li>
            <li>
              <Link
                className={`text-white p-[5px] text-[15px] block lg:inline-block no-underline hover:underline underline-offset-8 ${location.pathname.indexOf('about-us') > -1 ? 'underline' : ''}`}
                to="/pages/about-us"
                onClick={handleMenuItemClick}
              >
                About
              </Link>
            </li>
            <li>
              <Link
                className={`text-white p-[5px] text-[15px] block lg:inline-block no-underline hover:underline underline-offset-8 ${location.pathname.indexOf('advertisers') > -1 ? 'underline' : ''}`}
                to="/advertisers"
                onClick={handleMenuItemClick}
              >
                Advertisers
              </Link>
            </li>
            <li>
              <Link
                className={`text-white p-[5px] text-[15px] block lg:inline-block no-underline hover:underline underline-offset-8 ${location.pathname.indexOf('partners') > -1 ? 'underline' : ''}`}
                to="/pages/partners"
                onClick={handleMenuItemClick}
              >
                Partners
              </Link>
            </li>
            <li>
              <Link
                className={`text-white p-[5px] text-[15px] block lg:inline-block no-underline hover:underline underline-offset-8 ${location.pathname.indexOf('faq') > -1 ? 'underline' : ''}`}
                to="/faq"
                onClick={handleMenuItemClick}
              >
                FAQs
              </Link>
            </li>
            <li>
              <Link
                className={`text-white p-[5px] text-[15px] block lg:inline-block no-underline hover:underline underline-offset-8 ${location.pathname.indexOf('get-verified') > -1 ? 'underline' : ''}`}
                to="/get-verified"
                onClick={handleMenuItemClick}
              >
                Get Verified
              </Link>
            </li>
            <li>
              <Link
                className={`text-white p-[5px] text-[15px] block lg:inline-block no-underline hover:underline underline-offset-8 ${location.pathname.indexOf('program-your-social-badge') > -1 ? 'underline' : ''}`}
                to="/pages/program-your-social-badge"
                onClick={handleMenuItemClick}
              >
                Social Badge
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </header>

  );
};

export default Header;
