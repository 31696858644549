import { createSlice } from '@reduxjs/toolkit';
import axiosServices from '../utils/axios';
import { dispatch } from './index';

const initialState = {
    errorMessage: null,
    successMessage: null,
    respStatus: 0,
    profileDetails: {}
};

const profileSlice = createSlice({
    name: 'profile',
    initialState,
    reducers: {
        hasError(state, action) {
            state.errorMessage = action.payload;
        },
        setProfile(state, action) {
            state.profileDetails = action.payload;
        },
        hasSuccess(state, action) {
            state.successMessage = action.payload;
        },
        hasStatus(state, action) {
            state.respStatus = action.payload;
        }
    }
});

export default profileSlice.reducer;

export const getProfile = (apiUrl, slug) => {
    return async () => {
        try {
            const resp = await axiosServices.get(`${apiUrl}?slug=${slug}`, {});
            const { status, response} = resp.data;
            dispatch(profileSlice.actions.hasStatus(status))
            if (status && status === 1) {
                dispatch(profileSlice.actions.setProfile(response));
            }
        } catch (error) {
            dispatch(profileSlice.actions.setProfile({}));
        }
    }
}

export const clearProfile = () => {
    return async () => {
        dispatch(profileSlice.actions.setProfile({}));
    }
}