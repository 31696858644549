import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from '../store';
import { getPage, clearPage } from '../store/page';
import './index.css';
import Background from "../assets/img/frame-bg.png";
import "swiper/css/bundle";
import PageLoader from '../Loader/pages';

const Pages = () => {
    const dispatch = useDispatch();
    const { slug } = useParams();
    const { respStatus, pageDetails } = useSelector((state) => state.page);
    const [page, setPage] = useState({});

    const createMarkup = () => {
        return { __html: `${page.content ? page.content : ''}` };
    }

    useEffect(() => {
        dispatch(clearPage());
        let apiUrl = `user/site/view-page`;
        dispatch(getPage(apiUrl, slug));
    }, [slug, dispatch]);

    useEffect(() => {
        if (respStatus && respStatus === 1) {
            setPage(pageDetails);
        }
    }, [pageDetails, respStatus]);

    return (
        <div className='bg-[#f3f2f7]'>
            {!pageDetails || (!pageDetails?._id && <PageLoader />)}
            <div className="pt-[65px]"> 
            <div className="relative">
            <div className="absolute custom-bg-size bg-no-repeat custom-bg-position inset-0 opacity-30" style={{ backgroundImage: `url(${Background})` }}>  </div>
                <div className="relative w-full h-auto flex justify-center">
                    <div className="container 2xl:max-w-[1280px] 3xl:max-w-[1536px] static bg-[#000] opacity-[0.70] px-[30px] md:px-[50px] pb-[30px] md:pb-[50px] sm:w-[100%] md:w-[92%] lg:w-[97%] 4xl:w-[98%] lg:mt-[-0.15rem] 4xl:mt-[-0.11rem] mt-[0.7rem] md:mt-[0.7rem] rounded-b-[15px] flex items-center justify-center">
                           
                                <div dangerouslySetInnerHTML={createMarkup()} />
                           
                        </div>
                    </div>
                </div>
          
            </div>
        </div>
    );
};

export default Pages;
