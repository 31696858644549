import axios from 'axios';
let url = process.env.REACT_APP_LIVE_API_URL;
if (document?.location?.hostname === 'localhost') {
    url = process.env.REACT_APP_LOCAL_API_URL;
} else if (document?.location?.hostname === 'dev.aeedee.com') {
    url = process.env.REACT_APP_DEV_API_URL;
} else if (document?.location?.hostname === 'qa.aeedee.com') {
    url = process.env.REACT_APP_QA_API_URL;
} else if (document?.location?.hostname === 'beta.aeedee.com') {
    url = process.env.REACT_APP_BETA_API_URL;
}
const userToken = localStorage.getItem('user-token');
const authid = localStorage.getItem('auth-id');
if (userToken && userToken !== '') {
    axios.defaults.headers.common['Authorization'] = `Bearer ${userToken}`;
}
if (authid && authid !== '') {
    axios.defaults.headers.common['Authid'] = authid;
}
const axiosServices = axios.create({ baseURL: url || 'http://localhost:5000/' });
axiosServices.interceptors.response.use(
    (response) => response,
    (error) => {
        const errorData = error?.response?.data || error;
        return Promise.reject(errorData);
      }
      );

export default axiosServices;
