import * as React from 'react';
import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';
import './loader.css';
import Background from "../assets/img/frame-bg.png"

const PageLoader = () => {
	return (
        <div className="pt-[65px] min-h-screen">
<div className="relative">
<div className="absolute custom-bg-size bg-no-repeat custom-bg-position inset-0 opacity-30" style={{ backgroundImage: `url(${Background})` }}>  </div>       
 <div className="relative w-full h-auto flex justify-center" >
        <div className="container 2xl:max-w-[1280px] 3xl:max-w-[1536px]  skeleton-loader pages-container static bg-[#000] opacity-[0.70] p-[30px] md:p-[50px] sm:w-[87%] md:w-[92%] lg:w-[97%] 4xl:w-[98%] lg:mt-[-0.15rem] 4xl:mt-[-0.11rem] mt-[0.7rem] md:mt-[0.7rem] rounded-b-[15px]">

        <Stack className='stack' spacing={10}> 
                        <Skeleton className="small-box" variant="rounded" width={180} height={60} />
                        <Skeleton className="small-box" variant="rounded" width={210} height={60} />
                        <Skeleton variant="rectangular-para" width={210} height={60} />
                        <Skeleton variant="rectangular-para" width={210} height={60} />
                        <Skeleton variant="rectangular-para" width={210} height={60} />
                        <Skeleton variant="rectangular-para" width={210} height={60} />
                        <Skeleton variant="rectangular-para" width={210} height={60} />
                        <Skeleton variant="rectangular-para" width={210} height={60} />
                        <Skeleton variant="rectangular-para" width={210} height={60} />
                        <Skeleton variant="rectangular-para" width={210} height={60} />
                        <Skeleton variant="rectangular-para" width={210} height={60} />
                        <Skeleton variant="rectangular-para" width={210} height={60} />
                        <Skeleton className="button-box" variant="rounded" width={70} height={80} />
                    </Stack>
                </div>
            
        </div>
    </div>
    
      </div>
	);
};

export default PageLoader
