import React, { useState, useEffect, useMemo } from 'react'
import { FormHelperText, TextField, FormControl, MenuItem, Box } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { GlobalStyles } from '@mui/system';
import { openSnackbar } from '../store/snackbar';
import DatePicker from "react-multi-date-picker";
import { useDropzone } from 'react-dropzone';
import CancelIcon from '@mui/icons-material/Cancel';
import parsePhoneNumber from 'libphonenumber-js'
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { MarketResearchSchema } from '../utils/validation-schema'
import { dispatch, useSelector } from '../store';
import { commonAPICall, commonClearMessages } from '../store/common';
import PopupModal from './Popup-modal';
import calender from '../assets/img/calendar-solid1.png';
import Background from "../assets/img/frame-bg.png"
import Background1 from "../assets/img/bg-img.jpg";
import pdfempty from '../assets/img/empty-pdf.png';
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import './index.css'

const enquiryReason = [
	{ id: 1, 'value': 'General Inquiry', label: "General Inquiry" },
	{ id: 2, 'value': 'Start a new project', label: "Start a new project" },
	{ id: 3, 'value': 'Inquire about an ongoing project', label: "Inquire about an ongoing project" }
]

const Market = () => {
	const defaultValues = useMemo(() => ({
		reason: 'General Inquiry',
		name: '',
		phone: '',
		title: '',
		email: '',
		companyName: '',
		companyPhone: '',
		companyAddress: '',
		projectNumber: 0,
		projectTitle: '',
		projectDetails: '',
		launchDate: new Date()
	}), []);

	const methods = useForm({ resolver: yupResolver(MarketResearchSchema), defaultValues });
	const { reset, handleSubmit, control } = methods;
	const { commonErrorMessage, commonSuccessMessage, commonRespStatus, commonActionState, commonButtonState } = useSelector((state) => state.common);
	const [files, setFiles] = useState([]);
	const [disabledButton, setDisabledButton] = useState(false);
	const [verifyModalPopup, setVerifyModalPopup] = useState(false);
	const [fileRejectionItems, setFileRejectionItems] = useState('');

	const handleClose = () => {
		setVerifyModalPopup(false)
	}

	const removeFile = (file) => {
		setFileRejectionItems('');
		let filesArr = files?.filter((e) => e !== file);
		if (filesArr?.length === 0) {
			setFiles([]);
		} else {
			setFiles(filesArr)
		}
	}

	const { getRootProps, getInputProps } = useDropzone({
		accept: {
			'image/jpeg': ['.jpeg', '.png', '.jpg'],
			'application/pdf': ['.pdf']
		},
		maxFiles: 5,
		multiple: true,
		maxSize: 3000000,
		onDrop: (acceptedFiles, fileRejections) => {
			setFileRejectionItems('');
			let ImageSizeError = '';
			fileRejections.forEach(({ file, errors }) => {
				if (errors?.[0]?.code === 'file-too-large') {
					ImageSizeError = `Image size must be less than 3MB`;
				}
			});
			if (ImageSizeError) {
				setFileRejectionItems(ImageSizeError);
				return;
			}
			let newfiles = acceptedFiles.map(file => Object.assign(file, {
				preview: URL.createObjectURL(file)
			}));
			let previewFiles = [...files, ...newfiles]
			if (previewFiles?.length > 0) {
				let totalSize = previewFiles.reduce((accumulator, currentValue) => {
					return accumulator + currentValue.size;
				}, 0)
				if (totalSize > 3000000) {
					return setFileRejectionItems(`Image size must be less than 3MB`);
				}
			}
			if (previewFiles?.length > 5) {
				setFileRejectionItems('Maximum of 5 images is allowed')
				return;
			}
			setFiles(previewFiles);
		}
	});

	const onSubmit = handleSubmit((data = {}) => {
		let formData = new FormData();
		const extractPhone = parsePhoneNumber(data?.phone);
		const extractcompanyPhone = parsePhoneNumber(data?.companyPhone);
		formData.append('reason', data.reason || '');
		formData.append('name', data.name || '');
		formData.append('launchDate', data.launchDate || '');
		formData.append('title', data.title || '');
		formData.append('email', data.email || '');
		formData.append('companyName', data.companyName || '');
		formData.append('companyAddress', data.companyAddress || '');
		formData.append('projectNumber', data.projectNumber || 0);
		formData.append('projectTitle', data.projectTitle || '');
		formData.append('projectDetails', data.projectDetails || '');
		if (extractPhone && Object.keys(extractPhone).length > 0) {
			formData.append('phoneDetails', JSON.stringify({ 'countryCode': extractPhone?.country, 'nationalNumber': extractPhone?.nationalNumber, 'numberValue': extractPhone?.number, 'countryCallingCode': extractPhone?.countryCallingCode }));
		}
		if (extractcompanyPhone && Object.keys(extractcompanyPhone).length > 0) {
			formData.append('companyPhoneDetails', JSON.stringify({ 'countryCode': extractcompanyPhone?.country, 'nationalNumber': extractcompanyPhone?.nationalNumber, 'numberValue': extractcompanyPhone?.number, 'countryCallingCode': extractcompanyPhone?.countryCallingCode }));
		}
		if (files && files.length > 0) {
			files.forEach((file, i) => {
				formData.append(`userfiles[]`, file, file.name);
			});
		}
		setDisabledButton(true)
		dispatch(commonAPICall(`user/site/save/market-research`, `POST`, `save-market-research`, formData))
	})

	useEffect(() => {
		dispatch(commonClearMessages());
	}, [])

	useEffect(() => {
		if (commonActionState === 'save-market-research') {
			setDisabledButton(false)
		}
		if (commonSuccessMessage !== null && commonRespStatus === 1) {
			if (commonActionState === 'save-market-research') {
				reset();
				const body = document.querySelector('#root');
				body.scrollIntoView({
					behavior: 'smooth'
				}, 500)
				setVerifyModalPopup(true)
				setFiles([])
			}
			dispatch(commonClearMessages())
		} else if (commonErrorMessage !== null) {
			dispatch(
				openSnackbar({
					open: true,
					message: commonErrorMessage,
					variant: 'alert',
					alert: {
						color: 'error'
					},
					close: true
				})
			);
			dispatch(commonClearMessages())
		}
	}, [commonErrorMessage, commonSuccessMessage, commonRespStatus, commonActionState, commonButtonState,reset])

	return (
		<div className="pt-[4.7rem] lg:pt-[3.9rem] min-h-screen bg-[#f3f2f7]">
			<div
				style={{
					backgroundImage: `url(${Background1})`,
					backgroundSize: 'cover',
					backgroundPosition: 'center',
					backgroundRepeat: 'no-repeat',
				}}
			>
				<div className="relative">
					<div className="absolute custom-bg-size bg-no-repeat custom-bg-position inset-0 opacity-30" style={{ backgroundImage: `url(${Background})` }}>  </div>
					<div className="relative w-full h-auto flex justify-center">
						<div className="container 2xl:max-w-[1280px] 3xl:max-w-[1536px]  bg-[#000] bg-opacity-70 p-4 md:p-8 w-full max-w-[3xl] rounded-b-lg min-h-[500px]">
							<div className="relative z-10">
								<div className="mb-8 text-center">
									<div className="text-3xl md:text-3xl font-semibold">Market Research Feedback Form</div>
									<div className="text-sm md:text-lg text-[#f6e2b9] mt-[12px]">
										Collect reliable market feedback, identify needs and improve product solutions
									</div>
								</div>
								<div className="flex flex-col items-center justify-center min-h-screen">
									<div className="w-full max-w-[55rem] border border-[#999999] rounded-3xl p-4 md:p-8">
										<GlobalStyles
											styles={{
												'input:-webkit-autofill': {
													WebkitBoxShadow: '0 0 0 100px #fff inset !important',
													WebkitTextFillColor: '#000 !important',
													caretColor: '#000 !important',
													borderRadius: 'inherit',
												},
											}}
										/>
										<form onSubmit={handleSubmit(onSubmit)} autoComplete='off'>
											<div className="mb-6">
												<label htmlFor="countries" className="block mb-2 text-sm font-medium text-[#fff] ">
													Select Your Reason
												</label>
												<Controller
													name="reason"
													control={control}
													render={({ field, fieldState: { error } }) => (
														<TextField
															select
															{...field}
															size="small"
															fullWidth
															value={field.value}
															onChange={(event) => field.onChange(event.target.value)}
															helperText={error ? error?.message : ""}
															error={!!error}
															slotProps={{
																input:{
																className: "!bg-[#fff] border border-gray-300 text-gray-900 !text-[#000] text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full"
															}}}
															SelectProps={{
																MenuProps: {
																	disableScrollLock: true,
																	disablePortal: true
																}
															}}
														>
															<MenuItem value=""><em>None</em></MenuItem>
															{enquiryReason?.map((option) => (
																<MenuItem key={option.id} value={option.value}>
																	{option.label}
																</MenuItem>
															))}
														</TextField>
													)}
												/>
											</div>
											<div className="grid gap-6 mb-6 md:grid-cols-2">
												<div>
													<label htmlFor="your_name" className="block mb-2 text-sm font-medium text-[#fff]">
														Your Name
													</label>
													<Controller
														name="name"
														control={control}
														render={({ field, fieldState: { error } }) => (
															<TextField
																{...field}
																size="small"
																fullWidth
																value={field?.value}
																onChange={(event) => field.onChange(event.target.value)}
																placeholder="John"
																helperText={error ? error?.message : ""}
																error={!!error}
																slotProps={{
																	input:{
																	className: "!bg-[#fff] border border-gray-300 text-gray-900 !text-[#000] text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full"
																}}}															/>
														)}
													/>
												</div>

												<div>
												<FormControl fullWidth>
												<label htmlFor="phone" className="block mb-2 text-sm font-medium text-[#fff]">
														Phone Number
													</label>
                                                    <Controller
                                                        control={control}
                                                        name="phone"
                                                        render={({ field, fieldState }) => (
                                                            <Box className="phone-country-market">
                                                                <PhoneInput
                                                                    inputProps={{
                                                                        name: "phone",
                                                                        required: true,
                                                                        autoFocus: true,
                                                                    }}
																	country={"us"}
                                                                    value={field?.value}
                                                                    countryCodeEditable={false}
                                                                    enableSearch={true}
                                                                    disableSearchIcon={true}
                                                                    onChange={(value, country, e, formattedValue) => {
                                                                        field?.onChange(formattedValue)
                                                                    }}
																	sx={{
																		'& .MuiOutlinedInput-input': {
																		  color: '#000', 
																		},
																		'& .MuiInputLabel-root': {
																		  color: '#000', 
																		},
																	  }}
																	className="bg-[#fff] border-gray-300 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full text-[#000]"
                                                                    searchClass="phone-input-search-market"
                                                                    inputClass='phone-dropdown-market'
                                                                />
                                                                {fieldState.invalid ? (<FormHelperText error>{fieldState?.error?.message}</FormHelperText>) : null}
                                                            </Box>
                                                        )}
                                                    />
                                                </FormControl>
												</div>
												<div>
													<label htmlFor="title" className="block mb-2 text-sm font-medium text-[#fff]">
														Your Title
													</label>
													<Controller
														name="title"
														control={control}
														render={({ field, fieldState: { error } }) => (
															<TextField
																{...field}
																size="small"
																fullWidth
																value={field?.value}
																onChange={(event) => field.onChange(event.target.value)}
																placeholder="Enter your Title"
																helperText={error ? error?.message : ""}
																error={!!error}
																slotProps={{
																	input:{
																	className: "!bg-[#fff] border border-gray-300 text-gray-900 !text-[#000] text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full"
																}}}															/>
														)}
													/>
												</div>
												<div>
													<label htmlFor="email" className="block mb-2 text-sm font-medium text-[#fff]">
														Your Email Address
													</label>
													<Controller
														name="email"
														control={control}
														render={({ field, fieldState: { error } }) => (
															<TextField
																{...field}
																size="small"
																fullWidth
																type="email"
																value={field?.value}
																onChange={(event) => field.onChange(event.target.value)}
																placeholder="john.doe@company.com"
																helperText={error ? error?.message : ""}
																error={!!error}
																slotProps={{
																	input:{
																	className: "!bg-[#fff] border border-gray-300 text-gray-900 !text-[#000] text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full"
																}}}															/>
														)}
													/>
												</div>
												<div>
													<label htmlFor="company" className="block mb-2 text-sm font-medium text-[#fff]">
														Company Name
													</label>
													<Controller
														name="companyName"
														control={control}
														render={({ field, fieldState: { error } }) => (
															<TextField
																{...field}
																size="small"
																fullWidth
																value={field?.value}
																onChange={(event) => field.onChange(event.target.value)}
																placeholder="Enter your Company Name"
																helperText={error ? error?.message : ""}
																error={!!error}
																slotProps={{
																	input:{
																	className: "!bg-[#fff] border border-gray-300 text-gray-900 !text-[#000] text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full"
																}}}															/>
														)}
													/>
												</div>
												<div>
												<FormControl fullWidth>
												<label htmlFor="company_phone" className="block mb-2 text-sm font-medium text-[#fff]">
												Company Phone Number
													</label>
                                                    <Controller
                                                        control={control}
                                                        name="companyPhone"
                                                        render={({ field, fieldState }) => (
                                                            <Box className="phone-country-market">
                                                                <PhoneInput
                                                                    inputProps={{
                                                                        name: "phone",
                                                                        required: true,
                                                                        autoFocus: true,
                                                                    }}
																	country={"us"}
                                                                    value={field?.value}
                                                                    countryCodeEditable={false}
                                                                    enableSearch={true}
                                                                    disableSearchIcon={true}
                                                                    onChange={(value, country, e, formattedValue) => {
                                                                        field?.onChange(formattedValue)
                                                                    }}
																	sx={{
																		'& .MuiOutlinedInput-input': {
																		  color: '#000', 
																		},
																		'& .MuiInputLabel-root': {
																		  color: '#000', 
																		},
																	  }}
																	className="bg-[#fff] border-gray-300 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full text-[#000]"
                                                                    searchClass="phone-input-search-market"
                                                                    inputClass='phone-dropdown-market'
                                                                />
                                                                {fieldState.invalid ? (<FormHelperText error>{fieldState?.error?.message}</FormHelperText>) : null}
                                                            </Box>
                                                        )}
                                                    />
                                                </FormControl>
												</div>
											</div>
											<div className="mb-6">
												<label htmlFor="address" className="block mb-2 text-sm font-medium text-[#fff]">
													Company Address
												</label>
												<Controller
													name="companyAddress"
													control={control}
													render={({ field, fieldState: { error } }) => (
														<TextField
															{...field}
															size="small"
															fullWidth
															value={field?.value}
															onChange={(event) => field.onChange(event.target.value)}
															placeholder="Enter your company address"
															helperText={error ? error?.message : ""}
															error={!!error}
															slotProps={{
																input:{
																className: "!bg-[#fff] border border-gray-300 text-gray-900 !text-[#000] text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full"
															}}}														/>
													)}
												/>
											</div>
											<div className="grid grid-cols-1 gap-6 mb-6 md:grid-cols-2">
												<div>
													<label htmlFor="number" className="block mb-2 text-sm font-medium text-[#fff]">
														Project Number
													</label>
													<Controller
														name="projectNumber"
														control={control}
														render={({ field, fieldState: { error } }) => (
															<TextField
																{...field}
																size="small"
																fullWidth
																value={field.value === 0 ? '' : field.value}
																onChange={(event) => field.onChange(Number(event.target.value))}
																placeholder="Enter your Project number"
																type="number"
																helperText={error ? error?.message : ""}
																error={!!error}
																slotProps={{
																	input:{
																	className: "!bg-[#fff] border border-gray-300 text-gray-900 !text-[#000] text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full"
																}}}															/>
														)}
													/>
												</div>
												<div>
													<label htmlFor="date" className="block mb-2 text-sm font-medium text-[#fff]">
														Estimated Launch Date
													</label>
													<div className="relative">
														<div className="absolute mt-[0.5em] flex items-center pl-3 pointer-events-none">
															<img src={calender} alt="calendar" />
														</div>
														<Controller
															control={control}
															name="launchDate"
															render={({ field: { onChange, name, value }, formState: { errors } }) => {
																let d = new Date();
																return (
																	<>
																		<DatePicker
																			minDate={d.setDate(d.getDate() - 1)}
																			value={value || ""}
																			onChange={(date, { input, isTyping }) => {
																				if (isTyping) return false;
																				onChange(date?.isValid ? date : "")
																			}}
																			inputClass="!bg-[#fff] w-[100%] border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block pl-10 p-2.5"
																			placeholder="Select Date"
																		/>
																		{errors[name] && <FormHelperText error>{errors[name].message}</FormHelperText>}
																	</>
																)
															}}
														/>
													</div>
												</div>
											</div>
											<div className="mb-6">
												<label htmlFor="title" className="block mb-2 text-sm font-medium text-[#fff]">
													Project Title
												</label>
												<Controller
													name="projectTitle"
													control={control}
													render={({ field, fieldState: { error } }) => (
														<TextField
															{...field}
															size="small"
															fullWidth
															value={field?.value}
															onChange={(event) => field.onChange(event.target.value)}
															placeholder="Enter your project title"
															helperText={error ? error?.message : ""}
															error={!!error}
															slotProps={{
																input:{
																className: "!bg-[#fff] border border-gray-300 text-gray-900 !text-[#000] text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full"
															}}}														/>
													)}
												/>
											</div>
											<div className="grid gap-1 mb-6 md:grid-cols-1">
												<label htmlFor="summary" className="block text-sm font-medium text-[#fff]">
													Project Details
												</label>
												<Controller
													name="projectDetails"
													control={control}
													render={({ field, fieldState: { error } }) => (
														<TextField
															{...field}
															multiline
															rows={3}
															size="small"
															className="custom-summary"
															value={field.value}
															onChange={(event) => field.onChange(event.target.value)}
															placeholder="Write a Project Details"
															helperText={error ? error?.message : ""}
															error={!!error}
															slotProps={{
																input:{
																className: "!bg-[#fff] border border-gray-300 text-gray-900 !text-[#000] text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full"
															}}}														/>
													)}
												/>
											</div>
											<div className="grid gap-6 mb-6 md:grid-cols-1">
												<div className="flex flex-wrap items-start">
													<div {...getRootProps({ className: 'upload-container' })}>
														<label htmlFor="summary" className="block mb-2 text-sm font-medium text-[#fff]">
															Upload files
														</label>
														<label
															htmlFor="dropzone-file"
															className="flex flex-col items-center justify-center w-full md:w-[100%] h-[5.3rem] border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50"
														>
															<div className="flex flex-col items-center justify-center pt-5 pb-6">
																<svg
																	className="w-8 h-8 text-gray-500"
																	aria-hidden="true"
																	xmlns="http://www.w3.org/2000/svg"
																	fill="none"
																	viewBox="0 0 20 16"
																>
																	<path
																		stroke="currentColor"
																		strokeLinecap="round"
																		strokeLinejoin="round"
																		strokeWidth="2"
																		d="M13 13h3a3 3 0 0 0 0-6h-.025A5.56 5.56 0 0 0 16 6.5 5.5 5.5 0 0 0 5.207 5.021C5.137 5.017 5.071 5 5 5a4 4 0 0 0 0 8h2.167M10 15V6m0 0L8 8m2-2 2 2"
																	/>
																</svg>
															</div>
															<input {...getInputProps()} />
														</label>
													</div>
													<aside className='preview-container custom-flex'>
														{files?.map(file => {
															const src = file.type !== "application/pdf" ? file.preview : pdfempty;
															return (
																<>	<CancelIcon
																className="cancel-cc"
																onClick={() => removeFile(file)}
																style={{ cursor: 'pointer', margin: '-0.9rem' }}
															/>
															
																<div className={`preview-container-thumb`} key={file.name}>
																	<div className={`preview-container-inner`}>
																		<div className="custom-flex-container">
																	
																			<img
																				src={src}
																				className={`preview-container-img`}
																				alt="preview"
																				style={{ width: '100%', height: '100%', objectFit: 'cover' }}
																			/>
																			
																		</div>
																	</div>
																</div>
																</>	);
															})}
													</aside>
												</div>
												{fileRejectionItems && (<FormHelperText error>{fileRejectionItems}</FormHelperText>)}
											</div>
											<div>
												<div className="flex justify-center">
													<LoadingButton
														variant="contained"
														type="submit"
														loading={disabledButton}
														sx={{
															width: '100%',
															maxWidth: '200px',
															margin: '3rem auto',
															backgroundColor: '#1976D1',
															opacity: '1',
															color: '#fff',
															borderRadius: '32px'
														}}
													>
														Submit
													</LoadingButton>
												</div>
												{verifyModalPopup ? (
													<PopupModal verifyModalShow={verifyModalPopup} handleClose={handleClose} />
												) : null}
											</div>
										</form>
									</div>
								</div>
							</div>
						</div>

					</div>
				</div>
			</div>
		</div>


	)
}

export default Market