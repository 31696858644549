import { combineReducers } from 'redux';
import snackbarReducer from './snackbar';
import authReducer from './auth';
import pageReducer from './page'
import profileReducer from './profile'
import contactReducer from './contact'
import partnerReducer from './partner'
import commonReducer from './common'

const reducer = combineReducers({
    snackbar: snackbarReducer,
    auth: authReducer,
    common: commonReducer,
    page: pageReducer,
    profile: profileReducer,
    contact: contactReducer,
    partner: partnerReducer
});

export default reducer;