import { createSlice } from '@reduxjs/toolkit';
import axiosServices from '../utils/axios';
import { dispatch } from './index';

const initialState = {
    errorMessage: null,
    successMessage: null,
    respStatus: 0,
    actionState: null,
    buttonState: Date.now(),
    locationDetails: [],
    stateCityJson: []
};

const partnerSlice = createSlice({
    name: 'partner',
    initialState,
    reducers: {
        hasError(state, action) {
            state.errorMessage = action.payload;
        },
        hasSuccess(state, action) {
            state.successMessage = action.payload;
        },
        hasStatus(state, action) {
            state.respStatus = action.payload;
        },
        hasActionState(state, action) {
            state.actionState = action.payload;
        },
        hasButtonState(state, action) {
            state.buttonState = action.payload;
        },
        setLocationDetails(state, action) {
            state.locationDetails = action.payload;
        },
        setStateCityJson(state, action) {
            state.stateCityJson = action.payload;
        }
    }
});

export default partnerSlice.reducer;
export const partnerAPICall = (apiUrl, method, action, data) => {
    return async () => {
        try {
            let resp;
            if (method === 'GET') {
                resp = await axiosServices.get(apiUrl, data);
            } else if (method === 'POST') {
                resp = await axiosServices.post(apiUrl, data);
            }
            const { status, message, response } = resp.data;
            dispatch(partnerSlice.actions.hasStatus(status))
            dispatch(partnerSlice.actions.hasActionState(action))
            dispatch(partnerSlice.actions.hasButtonState(Date.now()))
            if (status && status === 1) {
                if (action === 'location-details') {
                    return dispatch(partnerSlice.actions.setLocationDetails(response));
                } else if (action === 'location-state-lists' || action === 'location-city-lists') {
                    return dispatch(partnerSlice.actions.setStateCityJson(response));
                }
                dispatch(partnerSlice.actions.hasSuccess(message));
            } else {
                dispatch(partnerSlice.actions.hasError(message));
            }
        } catch (error) {
            dispatch(partnerSlice.actions.hasStatus(0))
            dispatch(partnerSlice.actions.hasError(error));
        }
    }
}

export const clearMessages = () => {
    return async () => {
        dispatch(partnerSlice.actions.hasSuccess(null))
        dispatch(partnerSlice.actions.hasStatus(0))
        dispatch(partnerSlice.actions.hasError(null))
        dispatch(partnerSlice.actions.hasActionState(null))
    }
}