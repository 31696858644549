import PropTypes from 'prop-types';
import React, { useEffect, useState, useRef } from 'react';
import { Link } from 'react-router-dom';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Mousewheel, EffectFade, Pagination } from 'swiper/modules';
import $ from "jquery";
import Logo from "../assets/img/AeeDee-Logo-Dark.svg"
import AppleStore from "../assets/img/app-store-apple-logo.svg"
import GoogleStore from "../assets/img/google-play-logo.svg"
import BackgroundImage1 from "../assets/img/frame-1.webp"
import BackgroundImage2 from "../assets/img/frame-2.webp"
import BackgroundImage3 from "../assets/img/frame-3.webp"
import BackgroundImage4 from "../assets/img/frame-4.webp"
import BackgroundImage5 from "../assets/img/frame-5.webp"
import BackgroundImage6 from "../assets/img/frame-6.webp"
import BackgroundImage7 from "../assets/img/frame-7.webp"
import BackgroundImage8 from "../assets/img/frame-8.webp"
import BackgroundImage9 from "../assets/img/frame-9.webp"
import VideoPlaceHolder from "../assets/img/video-placeholder.webp"
import './index.css';
import "swiper/css/bundle"


const Home = ({ settingsDetails }) => {
	useEffect(() => {
		$(document).ready(function () {
			$('.popup_open').on('click', function () {
				let embed_url = $(this).find('.embed_url').val();
				$('.popup_wrap iframe').attr('src', embed_url);
				$('body').css('overflow', 'hidden');
				$('.popup_wrap').removeClass('hidden');
			});
			$('.popup_close').on('click', function () {
				$('.popup_wrap iframe').attr('src', '');
				$('body').css('overflow', 'inherit');
				$('.popup_wrap').addClass('hidden');
			});
		});
	}, [])
	const [popupOpen, setPopupOpen] = useState(false);
	const iframeRef = useRef(null);
  
	const handlePopupOpen = (url) => {
		setPopupOpen(true);
		setTimeout(() => {
			if (iframeRef.current) {
				iframeRef.current.src = url;
			}
		}, 0);
	};

	const handlePopupClose = () => {
		if (iframeRef.current) {
			iframeRef.current.src = '';
		}
		setPopupOpen(false);
	};

	const StoreRedirect = (url) => {
		let a = document.createElement("a");
		a.href = url || '';
		a.target = "_blank";
		a.click();
	}

	return (
		<div className='bg-[#222]'>
			<div className="swiper vslider w-full h-[100%]">
				<Swiper
					modules={[Mousewheel, EffectFade, Pagination]}
					direction="vertical"
					slidesPerView={1}
					spaceBetween={10}
					effect="fade"
					mousewheel={{
						forceToAxis: true,
						sensitivity: 3,
						releaseOnEdges: true,
					}}
					pagination={{
						clickable: true,
					}}
					keyboard={{
						enabled: true,
					}}
					speed={600}
					touchReleaseOnEdges={true}
					className="mySwiper max-h-[100vh] w-full"
				>
					<SwiperSlide index={0}>
						<div className="relative h-full bg-[#000]">
							<video className="absolute top-0 object-cover h-full w-full brightness-60" src={`https://aeedee-beta-c6i.s3.amazonaws.com/website/homepage-video.mp4`} autoPlay muted loop></video>
							<div className="absolute w-full h-full bg-[#00000090]"></div>
							<div className="lg:max-w-[1536px] mx-auto px-4 h-full relative z-99">
								<div className="h-full flex flex-col items-center justify-center">
									<img className="lg:w-[50%] w-[80%]" src={Logo} alt="logo" />
									<h2 className="text-white p-[20px] text-center lg:text-[25px] text-[18px]">
										Aiming to return online control back to the people.
									</h2>
									<div className="mt-[-20px]">
										<Link to={settingsDetails?.app_store_link || "https://www.apple.com/"} target='_blank'>
											<img className="object-cover w-[12rem] h-40 lg:inline-block px-5 leading-normal" src={AppleStore} alt="apple-store" />
										</Link>
										<Link to={settingsDetails?.google_play_link || "https://www.google.com/"} target='_blank'>
											<img className="object-cover w-[12rem] h-40 lg:inline-block px-5 leading-normal lg:mt-[0px] mt-[-70px]" src={GoogleStore} alt="google-play" />
										</Link>
									</div>

								</div>
							</div>
						</div>
					</SwiperSlide>
					<SwiperSlide index={1}>
						<div className="h-full bg-cover bg-no-repeat bg-center" style={{ backgroundImage: `url(${BackgroundImage1})` }}>
							<div className="lg:max-w-[1536px] mx-auto px-4 h-full flex justify-center lg:justify-start items-center lg:items-end">
								<h2 className="text-[#f6e2b9] text-[30px] text-center lg:text-left font-bold mb-[50px] bg-[#000] lg:opacity-[0.70] p-[50px] rounded-[20px] opacity-[0.80]">
									<span className="lg:text-[50px] text-[#fff] font-thin text-[25px]">Social Badge™</span><br />
									Program your badge with one click.
									<br />
									<span className="lg:text-[25px] text-[#fff] font-thin text-[20px]">Securely store contacts or befriend socially with one tap.</span><br />
									<Link to={{ pathname: "/pages/program-your-social-badge" }} className="text-white bg-[#1172ba] text-center p-[10px] rounded-[4px] block lg:inline-block text-[15px] font-thin mt-[20px]">
										Easy 3 Steps Process
									</Link>
								</h2>
							</div>
						</div>
					</SwiperSlide>
					<SwiperSlide index={2}>
						<div className="h-full bg-cover bg-no-repeat bg-center" style={{ backgroundImage: `url(${BackgroundImage2})` }}>
							<div className="lg:max-w-[1536px] mx-auto px-4 h-full flex justify-center lg:justify-start items-center lg:items-end">
								<h2 className="text-[#f6e2b9] text-[30px] text-center lg:text-left font-bold mb-[50px] bg-[#000] lg:opacity-[0.70] p-[50px] rounded-[20px] opacity-[0.80]">
									<span className="lg:text-[50px] text-[#fff] font-thin text-[25px]">One Voice System™</span><br />
									Fully integrated, user control, Super Social App™
									<br />
									<span className="lg:text-[25px] text-[#fff] font-thin text-[20px]">It's ethical and powerful, with Child Safety and Ad Control solutions. No more unwanted content, data selling, shadow banning, and information manipulation. It's your ultimate venting machine.</span>
								</h2>
							</div>
						</div>
					</SwiperSlide>
					<SwiperSlide index={3}>
						<div className="h-full bg-cover bg-no-repeat bg-center" style={{ backgroundImage: `url(${BackgroundImage3})` }}>
							<div className="lg:max-w-[1536px] mx-auto px-4 h-full flex justify-center lg:justify-start items-center lg:items-end">
								<h2 className="text-[#f6e2b9] text-[30px] text-center lg:text-left font-bold mb-[50px] bg-[#000] lg:opacity-[0.70] p-[50px] rounded-[20px] opacity-[0.80]">
									<span className="lg:text-[50px] text-[#fff] font-thin text-[25px]">Single Session Restricted Access</span><br />
									Most secure platform. Absolute peace of mind.
									<br />
									<span className="lg:text-[25px] text-[#fff] font-thin text-[20px]">Requires your device to access your account, plus an easy one-step restoration process, just in case you lose your device. — no more passwords, no more hacking headaches, ever.</span>
								</h2>
							</div>
						</div>
					</SwiperSlide>
					<SwiperSlide index={4}>
						<div className="h-full bg-cover bg-no-repeat bg-center" style={{ backgroundImage: `url(${BackgroundImage4})` }}>
							<div className="lg:max-w-[1536px] mx-auto px-4 h-full flex justify-center lg:justify-start items-center lg:items-end">
								<h2 className="text-[#f6e2b9] text-[30px] text-center lg:text-left font-bold mb-[50px] bg-[#000] lg:opacity-[0.70] p-[50px] rounded-[20px] opacity-[0.80]">
									<span className="lg:text-[50px] text-[#fff] font-thin text-[25px]">Profile Portal®</span><br />
									Consolidate your profiles.
									<br />
									<span className="lg:text-[25px] text-[#fff] font-thin text-[20px]">Only one AeeDee phone number to remember and share for all your connections from now on. </span>
								</h2>
							</div>
						</div>
					</SwiperSlide>
					<SwiperSlide index={5}>
						<div className="h-full bg-cover bg-no-repeat bg-center" style={{ backgroundImage: `url(${BackgroundImage5})` }}>
							<div className="lg:max-w-[1536px] mx-auto px-4 h-full flex justify-center lg:justify-start items-center lg:items-end">
								<h2 className="text-[#f6e2b9] text-[30px] text-center lg:text-left font-bold mb-[50px] bg-[#000] lg:opacity-[0.70] p-[50px] rounded-[20px] opacity-[0.80]">
									<span className="lg:text-[50px] text-[#fff] font-thin text-[25px]">PAL</span><br />
									Practical Alternatives™
									<br />
									<span className="lg:text-[25px] text-[#fff] font-thin text-[20px]">Your favorite tools minus the branding cost. No more re-registration, password memorization, and heavy expense.</span>
								</h2>

							</div>
						</div>
					</SwiperSlide>
					<SwiperSlide index={6}>
						<div className="h-full bg-cover bg-no-repeat bg-center" style={{ backgroundImage: `url(${BackgroundImage6})` }}>
							<div className="lg:max-w-[1536px] mx-auto px-4 h-full flex justify-center lg:justify-start items-center lg:items-end">
								<h2 className="text-[#f6e2b9] text-[30px] text-center lg:text-left font-bold mb-[50px] bg-[#000] lg:opacity-[0.70] p-[50px] rounded-[20px] opacity-[0.80]">
									<span className="lg:text-[50px] text-[#fff] font-thin text-[25px]">Microsites & Dynamic Gadgets</span><br />
									Instant customizable web presence.
									<br />
									<span className="lg:text-[25px] text-[#fff] font-thin text-[20px]">Takes seconds to build. No more lengthy planning, design, development, domain, hosting, and maintenance. Slight difference, same result, and it's FREE. </span>
								</h2>
							</div>
						</div>
					</SwiperSlide>
					<SwiperSlide index={7}>
						<div className="h-full bg-cover bg-no-repeat bg-center" style={{ backgroundImage: `url(${BackgroundImage7})` }}>
							<div className="lg:max-w-[1536px] mx-auto px-4 h-full flex justify-center lg:justify-start items-center lg:items-end">
								<h2 className="text-[#f6e2b9] text-[30px] text-center lg:text-left font-bold mb-[50px] bg-[#000] lg:opacity-[0.70] p-[50px] rounded-[20px] opacity-[0.60]">
									<span className="lg:text-[50px] text-[#fff] font-thin text-[25px]">Get Verified</span><br />
									Get verified to get paid.
									<br />
									<span className="lg:text-[25px] text-[#fff] font-thin text-[20px]">Verified accounts activate features that gives influencers huge advantages, and allow businesspeople and regular folks to easily conduct commerce, globally. </span><br />
									<Link to={{ pathname: "/get-verified" }} className="text-white bg-[#1172ba] text-center p-[10px] rounded-[4px] block lg:inline-block text-[15px] font-thin mt-[20px] ">Start Verification Process</Link>
								</h2>
							</div>
						</div>
					</SwiperSlide>
					<SwiperSlide index={8}>
						<div className="h-full bg-cover bg-no-repeat bg-center" style={{ backgroundImage: `url(${BackgroundImage8})` }}>
							<div className="lg:max-w-[1536px] mx-auto px-4 h-full flex justify-center lg:justify-start items-center lg:items-end">
								<h2 className="text-[#f6e2b9] text-[30px] text-center lg:text-left font-bold mb-[50px] bg-[#000] lg:opacity-[0.70] p-[50px] rounded-[20px] opacity-[0.60]">
									<span className="lg:text-[50px] text-[#fff] font-thin text-[25px]">Satellite Partnership Program</span><br />
									Become a partner, if you have what it takes.
									<br />
									<span className="lg:text-[25px] text-[#fff] font-thin text-[20px]">Imagine the benefits of owning a popular franchise, without all the heavy introductory costs. Truly an entrepreneur's dream. Check for availability and apply.</span><br />
									<Link to={{ pathname: "/become-a-partner" }} className="text-white bg-[#1172ba] min-w-[155px] text-center p-[10px] rounded-[4px] block lg:inline-block text-[15px] font-thin mt-[20px] ">Apply Here</Link>
								</h2>
							</div>
						</div>
					</SwiperSlide>
					<SwiperSlide index={9}>
						<div className="h-full bg-cover bg-no-repeat bg-center" style={{ backgroundImage: `url(${BackgroundImage9})` }}>
							<div className="lg:max-w-[1536px] mx-auto px-4 h-full flex justify-center lg:justify-start items-center lg:items-end">
								<h2 className="text-[#f6e2b9] text-[30px] text-center lg:text-left font-bold mb-[50px] bg-[#000] lg:opacity-[0.70] p-[50px] rounded-[20px] opacity-[0.60]">
									<span className="lg:text-[50px] text-[#fff] font-thin text-[25px]">Advertisers Portal</span><br />
									Easy and economical DIY advertising.
									<br />
									<span className="lg:text-[25px] text-[#fff] font-thin text-[20px]">It's as easy and economical as shopping for online items but effectively targets specific demographics without resorting to popular devious methods.</span><br />
									<Link to={{ pathname: "/advertisers" }} className="text-white bg-[#1172ba] min-w-[155px] text-center p-[10px] rounded-[4px] block lg:inline-block text-[15px] font-thin mt-[20px]">Advertise Now</Link>
								</h2>
							</div>
						</div>
					</SwiperSlide>
				</Swiper>
			</div>
			<section className="mt-[30px]">
				<div className="container lg:max-w-[1536px] mx-auto px-4">
					<div className="grid lg:grid-cols-4 items-center gap-[15px]">
						<div>
							<button
								onClick={() => handlePopupOpen('https://www.youtube.com/embed/KnWDuNdy-cs?si=KMpG7-4sS6m0XB6v')}
								className="cursor-pointer relative overflow-hidden rounded-[4px] w-full"
								aria-label="Open video popup"
							>
								<div className="absolute w-full h-full bg-[#000000b3]"></div>
								<span className="absolute top-[50%] left-[50%] w-[50px] translate-x-[-50%] translate-y-[-50%] z-9">
									<svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" stroke="#ffffff">
										<g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
										<g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g>
										<g id="SVGRepo_iconCarrier">
											<path fillRule="evenodd" clipRule="evenodd" d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22ZM10.6935 15.8458L15.4137 13.059C16.1954 12.5974 16.1954 11.4026 15.4137 10.941L10.6935 8.15419C9.93371 7.70561 9 8.28947 9 9.21316V14.7868C9 15.7105 9.93371 16.2944 10.6935 15.8458Z" fill="#ffffff"></path>
										</g>
									</svg>
								</span>
								<img className="w-full h-[170px] object-cover" src={VideoPlaceHolder} alt="video_thumbnail" />
							</button>
							<h3 className="text-center p-[10px] font-semibold text-[#dedede]">Why Join AeeDee?</h3>
						</div>
						<div>
							<button
								onClick={() => handlePopupOpen('https://www.youtube.com/embed/KnWDuNdy-cs?si=KMpG7-4sS6m0XB6v')}
								className="cursor-pointer relative overflow-hidden rounded-[4px] w-full"
								aria-label="Open video popup"
							>
								<div className="absolute w-full h-full bg-[#000000b3]"></div>
								<span className="absolute top-[50%] left-[50%] w-[50px] translate-x-[-50%] translate-y-[-50%] z-9">
									<svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" stroke="#ffffff">
										<g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
										<g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g>
										<g id="SVGRepo_iconCarrier">
											<path fillRule="evenodd" clipRule="evenodd" d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22ZM10.6935 15.8458L15.4137 13.059C16.1954 12.5974 16.1954 11.4026 15.4137 10.941L10.6935 8.15419C9.93371 7.70561 9 8.28947 9 9.21316V14.7868C9 15.7105 9.93371 16.2944 10.6935 15.8458Z" fill="#ffffff"></path>
										</g>
									</svg>
								</span>
								<img className="w-full h-[170px] object-cover" src={VideoPlaceHolder} alt="video_thumbnail" />
							</button>
							<h3 className="text-center p-[10px] font-semibold text-[#dedede]">AeeDee in General</h3>
						</div>
						<div>
							<button
								onClick={() => handlePopupOpen('https://www.youtube.com/embed/KnWDuNdy-cs?si=KMpG7-4sS6m0XB6v')}
								className="cursor-pointer relative overflow-hidden rounded-[4px] w-full"
								aria-label="Open video popup"
							>
								<div className="absolute w-full h-full bg-[#000000b3]"></div>
								<span className="absolute top-[50%] left-[50%] w-[50px] translate-x-[-50%] translate-y-[-50%] z-9">
									<svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" stroke="#ffffff">
										<g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
										<g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g>
										<g id="SVGRepo_iconCarrier">
											<path fillRule="evenodd" clipRule="evenodd" d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22ZM10.6935 15.8458L15.4137 13.059C16.1954 12.5974 16.1954 11.4026 15.4137 10.941L10.6935 8.15419C9.93371 7.70561 9 8.28947 9 9.21316V14.7868C9 15.7105 9.93371 16.2944 10.6935 15.8458Z" fill="#ffffff"></path>
										</g>
									</svg>
								</span>
								<img className="w-full h-[170px] object-cover" src={VideoPlaceHolder} alt="video_thumbnail" />
							</button>
							<h3 className="text-center p-[10px] font-semibold text-[#dedede]">About the Social Badge</h3>
						</div>
						<div>
							<button
								onClick={() => handlePopupOpen('https://www.youtube.com/embed/KnWDuNdy-cs?si=KMpG7-4sS6m0XB6v')}
								className="cursor-pointer relative overflow-hidden rounded-[4px] w-full"
								aria-label="Open video popup"
							>
								<div className="absolute w-full h-full bg-[#000000b3]"></div>
								<span className="absolute top-[50%] left-[50%] w-[50px] translate-x-[-50%] translate-y-[-50%] z-9">
									<svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" stroke="#ffffff">
										<g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
										<g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g>
										<g id="SVGRepo_iconCarrier">
											<path fillRule="evenodd" clipRule="evenodd" d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22ZM10.6935 15.8458L15.4137 13.059C16.1954 12.5974 16.1954 11.4026 15.4137 10.941L10.6935 8.15419C9.93371 7.70561 9 8.28947 9 9.21316V14.7868C9 15.7105 9.93371 16.2944 10.6935 15.8458Z" fill="#ffffff"></path>
										</g>
									</svg>
								</span>
								<img className="w-full h-[170px] object-cover" src={VideoPlaceHolder} alt="video_thumbnail" />
							</button>
							<h3 className="text-center p-[10px] font-semibold text-[#dedede]">About the Growing Problem</h3>
						</div>
					</div>
				</div>
				{popupOpen && (
					<div className="popup_wrap fixed top-0 left-0 w-full h-full bg-[#00000099] z-[99]">
						<div className="absolute top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%] w-full lg:w-[auto]">
							<iframe
								ref={iframeRef}
								title="titleProperty"
								className="w-[100%] h-[200px] lg:w-[550px] lg:h-[300px]"
								src=""
								style={{ border: "0" }}
								allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
							></iframe>
							<button
								onClick={handlePopupClose}
								className="popup_close cursor-pointer absolute w-[20px] right-[0] lg:right-[inherit] lg:left-[100%] top-0 m-[10px] bg-transparent" // Ensure the button has a transparent background
								aria-label="Close video popup"
							>
								<svg
									fill="#ffffff"
									viewBox="0 0 32 32"
									version="1.1"
									xmlns="http://www.w3.org/2000/svg"
									stroke="#ffffff"
								>
									<g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
									<g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g>
									<g id="SVGRepo_iconCarrier">
										<title>Close</title>
										<path d="M17.062 16l9.37-9.37c0.136-0.136 0.219-0.323 0.219-0.53 0-0.415-0.336-0.751-0.751-0.751-0.208 0-0.395 0.084-0.531 0.22v0l-9.369 9.369-9.37-9.369c-0.135-0.131-0.319-0.212-0.522-0.212-0.414 0-0.75 0.336-0.75 0.75 0 0.203 0.081 0.387 0.212 0.522l9.368 9.369-9.369 9.369c-0.136 0.136-0.22 0.324-0.22 0.531 0 0.415 0.336 0.751 0.751 0.751 0.207 0 0.394-0.084 0.53-0.219v0l9.37-9.37 9.369 9.37c0.136 0.136 0.324 0.22 0.531 0.22 0.415 0 0.751-0.336 0.751-0.751 0-0.207-0.084-0.395-0.22-0.531v0z"></path>
									</g>
								</svg>
							</button>
						</div>
					</div>
				)}
			</section>
		</div>
	);
};

export default Home;

Home.propTypes = {
	settingsDetails: PropTypes.object
};
