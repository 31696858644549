import React, { useEffect, useState, useCallback } from 'react';
import { commonAPICall, commonClearMessages } from '../../store/common';
import { dispatch, useSelector } from '../../store';
import Background from '../../assets/img/frame-bg.png';
import './index.css';
import minus from '../../assets/img/-Button.png';
import plus from '../../assets/img/+Button.png';
import { Link } from 'react-router-dom';


const Faq = () => {
	const { commonRespStatus, commonActionState, commonButtonState, faqModuleLists, faqLists } = useSelector((state) => state.common);
	const [activeIndex, setActiveIndex] = useState(null);
	const [currentTopic, setCurrentTopic] = useState('');
	const [faqModuleData, setFaqModuleData] = useState([]);
	const [faqData, setFaqData] = useState([]);
	const [slideIndex, setSlideIndex] = useState(1);
	const [openQuestionIndex, setOpenQuestionIndex] = useState(null);

	const handleMenuItemClick = (topic, index) => {
		setCurrentTopic(topic?.title);
		setActiveIndex(index);
		getFaqLists(topic?._id);
	};

	const getFaqLists = useCallback((id) => {
		dispatch(commonAPICall(`user/site/faq-lists?appId=${id}`, 'GET', 'faq-lists', {}));
	}, []);  

	useEffect(() => {
		dispatch(commonClearMessages());
		dispatch(commonAPICall(`user/site/faq-module-lists`, 'GET', 'faq-module-lists', {}));
	}, []);  

	useEffect(() => {
		if (commonRespStatus === 1) {
			if (commonActionState === 'faq-module-lists') {
				setFaqModuleData(faqModuleLists);
				if (faqModuleLists?.length > 0) {
					setCurrentTopic(faqModuleLists[0]?.title);
					getFaqLists(faqModuleLists[0]?._id);
				}
			}
			if (commonActionState === 'faq-lists') {
				setFaqData(faqLists);
			}
		}
	}, [commonRespStatus, commonActionState, commonButtonState, faqModuleLists, faqLists, getFaqLists]);  // Added getFaqLists as a dependency

	const toggleQuestion = (index) => {
		setOpenQuestionIndex(openQuestionIndex === index ? null : index);
	};
	const plusSlides = (n) => {
		showSlides(slideIndex + n);
	};
	const showSlides = useCallback((n) => {
		const slides = document.getElementsByClassName('mySlides');
		const buttons = document.querySelectorAll('.section-desktop .topic');
		if (n > slides.length) {
			setSlideIndex(1);
			return;
		}
		if (n < 1) {
			setSlideIndex(slides.length);
			return;
		}
		for (let x of slides) {
			x.style.display = 'none';
		}
		slides[n - 1].style.display = 'block';

		buttons.forEach(button => {
			button.classList.remove('active-topic');
			if (parseInt(button.getAttribute('data-index')) === n - 1) {
				setCurrentTopic(button.getAttribute('data-name'));
				getFaqLists(button.getAttribute('data-id'));
				button.classList.add('active-topic');
			}
		});
		setSlideIndex(n);
	}, [getFaqLists]);

	const currentSlide = useCallback((n) => {
		showSlides(n);
	}, [showSlides]);

	useEffect(() => {
		currentSlide(1);

		const buttons = document.querySelectorAll('.topic');
		buttons.forEach(button => {
			const clickHandler = function () {
				const index = parseInt(button.getAttribute('data-index'));
				console.log('index:', index);
				currentSlide(index + 1);
			};
			button.addEventListener('click', clickHandler);

			return () => {
				button.removeEventListener('click', clickHandler);
			};
		});
	}, [currentSlide]);

	useEffect(() => {
		currentSlide(1);
	}, [faqModuleData, currentSlide]);

	useEffect(() => {
		showSlides(slideIndex);
	}, [showSlides, slideIndex]);

	return (
		<div className="pt-[65px] bg-[#f3f2f7] min-h-screen">
			<div className="relative">
				<div className="absolute custom-bg-size bg-no-repeat custom-bg-position inset-0 opacity-10" style={{ backgroundImage: `url(${Background})` }}></div>
				<div className="relative w-full h-auto flex justify-center">
					<section className="sm:max-w-[1024px] container 2xl:max-w-[1280px] 3xl:max-w-[1536px] static bg-[#000] opacity-[0.70] p-[20px] md:px-[50px] md:pt-[0px] md:pb-[50px] sm:w-[100%] md:w-[92%] lg:w-[97%] 4xl:w-[98%] lg:mt-[-0.15rem] 4xl:mt-[-0.11rem] mt-[0.7rem] md:mt-[0.7rem] rounded-b-[15px] h-[840px] overflow-y-auto overflow-x-hidden hide-scrollbar">
						<section className="w-[100%] sm:w-[100%] md:w-[100%] lg:w-[100%] xl:w-[100%] 2xl:w-[100%] max-w-full ml-[0rem] sm:ml-[0rem] md:ml-[0rem] lg:ml-[4rem] mt-[0rem] p-4 text-center md:text-center lg:text-left">
							<h1 className="lg:w-[100%] md:leading-[3.5rem] lg:leading-[4.75rem] lg:text-[70px] md:text-[50px] sm:text-[35px] text-[30px] font-medium text-[#fff] text-center lg:text-left">
								AeeDee FAQs{' '}
								<span className="block lg:text-[50px] md:text-[60px] sm:text-[45px] text-[30px] font-medium text-[#f6e2b9]">
									Your questions answered
								</span>
							</h1>
						</section>

						<div className="slideshow-container topics">
							{faqModuleData?.map((item, i) => (
								<div className="mySlides" key={item?._id}>
									<button
										className="author topic"
										data-index={i}
										data-id={item?._id}
										data-name={item?.title}
										onClick={() => handleMenuItemClick(item)}
									>
										{item?.title}
									</button>
								</div>
							))}
							<Link className="prev" onClick={() => plusSlides(-1)}>❮</Link>
							<Link className="next" onClick={() => plusSlides(1)}>❯</Link>
						</div>

						<section className="section-desktop lg:w-full p-4 mt-[0rem] pl-[1rem] md:pl-[7rem] lg:pl-[0rem] lg:mt-[5rem] xl:pl-[5rem]">
							<h2 className="text-sm text-[#fff] font-medium mb-4 sm:relative sm:ml-[7rem] lg:ml-[0rem] ">
								CHOOSE YOUR TOPIC
							</h2>
							<ul className="topics mt-8 list-none p-0 text-[#fff] grid grid-cols-1 md:grid-cols-2 gap-4 lg:grid-cols-1">
								{faqModuleData?.map((item, i) => (
									<li
										className="mb-[2.25rem] border border-[#1A1D23] rounded-full w-fit h-[32px] px-[16px] py-[3px] bg-[#1A1D23]"
										key={item?._id}
									>
										<button
											className={`topic ${activeIndex === i ? 'active-topic' : ''}`}
											data-index={i}
											data-id={item?._id}
											data-name={item?.title}
											onClick={() => handleMenuItemClick(item, i)}
										>
											{item?.title}
										</button>
									</li>
								))}
							</ul>


						</section>

						<div className="h-auto mt-[1rem] md:mt-[2rem] lg:mt-[-41rem]">
							<div className="text-[#fff] relative w-[100%] lg:w-[60%] xl:w-[50%] 2xl:w-[60%] ml-[0rem] sm:ml-[0rem] md:ml-[0rem] lg:ml-[18.6rem] xl:ml-[27.3rem]">
								{currentTopic && (
									<div>
										<h2 className="md:text-[35px] text-[26px] font-semibold mb-[1rem] hidden md:block">
											{currentTopic}
										</h2>


										{faqData.map((item, index) => (
											<div key={item?._id} className="faq-item mb-[1.5rem]">
												<div className="question-container flex items-center justify-between">
													<button
														className="question text-[18px] text-left w-[100%] lg:w-auto"
														onClick={() => toggleQuestion(index)}
													>
														{item.question}
													</button>
													<button onClick={() => toggleQuestion(index)}>
														{openQuestionIndex === index ? (
															<img className="w-[70%]" src={minus} alt="Minus" />
														) : (
															<img className="w-[70%]" src={plus} alt="Plus" />
														)}
													</button>
												</div>
												<div
													className={`answer-container text-[#9A9DAC] text-[16px] mt-[1rem]  w-[90%] lg:w-[87%]  transition-all duration-500 ease-in-out overflow-hidden ${openQuestionIndex === index ? 'max-h-[1000px] opacity-100' : 'max-h-0 opacity-0'
														}`}
												>
													{item.answer}
												</div>
											</div>
										))}
									</div>
								)}
							</div>
						</div>
					</section>
				</div>
			</div>
		</div>

	)
}

export default Faq