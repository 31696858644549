import React from 'react'
import { Link } from 'react-router-dom'
import FooterLogo from "../../assets/img/ad-hash.svg"
const currentYear = new Date().getFullYear()

const Footer = () => {
    return (
        <footer className="py-[15px] bg-[#222]">
            <div className="container lg:max-w-[1536px] mx-auto px-4">
                <div className="flex items-center justify-between">
                    <p className="text-xs text-[#dedede] !tracking-normal">Copyright © {currentYear} AeeDee®. All rights reserved.</p>
                    <div>
                        <Link to="/" className="w-[50px] h-[50px] block rounded-[4px] p-[3px]">
                            <img className="w-full h-full" src={FooterLogo} alt="logo" />
                        </Link>
                    </div>
                </div>
            </div>
        </footer>
    )
}

export default Footer