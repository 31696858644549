import { createSlice } from '@reduxjs/toolkit';
import axiosServices from '../utils/axios';
import { dispatch } from './index';

const initialState = {
    errorMessage: null,
    successMessage: null,
    respStatus: 0,
    buttonState: Date.now(),
    actionState: '',
    otpValue: '',
    userStorage: {},
    profile: {},
    generalSettings: {}
};

const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        hasError(state, action) {
            state.errorMessage = action.payload;
        },
        setOTP(state, action) {
            state.otpValue = action.payload;
        },
        userStorage(state, action) {
            state.userStorage = action.payload;
        },
        setProfile(state, action) {
            state.profile = action.payload;
        },
        setActionState(state, action) {
            state.actionState = action.payload
        },
        hasSuccess(state, action) {
            state.successMessage = action.payload;
        },
        hasStatus(state, action) {
            state.respStatus = action.payload;
        },
        hasButtonState(state, action) {
            state.buttonState = action.payload;
        },
        setGeneralSettings(state, action) {
            state.generalSettings = action.payload;
        }
    }
});

export default authSlice.reducer;

export const authLogin = (apiUrl, data) => {
    return async () => {
        try {
            const resp = await axiosServices.post(apiUrl, data);
            const { status, message, otp } = resp.data;
            dispatch(authSlice.actions.hasStatus(status))
            dispatch(authSlice.actions.hasButtonState(Date.now()))
            if (status && status === 1) {
                // localStorage.setItem('user-token', response.userToken)
                dispatch(authSlice.actions.setOTP(otp));
                dispatch(authSlice.actions.hasSuccess(message));
            } else {
                dispatch(authSlice.actions.hasError(message));
            }
        } catch (error) {
            dispatch(authSlice.actions.hasButtonState(Date.now()))
            dispatch(authSlice.actions.hasStatus(0))
            dispatch(authSlice.actions.hasError(error.message));
        }
    }
}

export const verifyOTP = (apiUrl, data) => {
    return async () => {
        try {
            const resp = await axiosServices.post(apiUrl, data);
            const { status, message, userToken, completeProfile, authid } = resp.data;
            dispatch(authSlice.actions.hasStatus(status))
            dispatch(authSlice.actions.hasButtonState(Date.now()))
            if (status && status === 1) {
                dispatch(authSlice.actions.userStorage({ userToken, completeProfile, authid }));
                dispatch(authSlice.actions.hasSuccess(message));
            } else {
                dispatch(authSlice.actions.hasError(message));
            }
        } catch (error) {
            dispatch(authSlice.actions.hasButtonState(Date.now()))
            dispatch(authSlice.actions.hasStatus(0))
            dispatch(authSlice.actions.hasError(error.message));
        }
    }
}

export const getProfile = (apiUrl, data) => {
    return async () => {
        try {
            const resp = await axiosServices.post(apiUrl, data);
            const { status, response } = resp.data;
            if (status && status === 1) {
                dispatch(authSlice.actions.setProfile(response));
            }
        } catch (error) {
            dispatch(authSlice.actions.setProfile({}));
        }
    }
}

export const saveProfile = (apiUrl, data) => {
    return async () => {
        try {
            const resp = await axiosServices.post(apiUrl, data);
            const { status, message } = resp.data;
            dispatch(authSlice.actions.hasStatus(status))
            dispatch(authSlice.actions.hasButtonState(Date.now()))
            dispatch(authSlice.actions.setActionState('save-profile'));
            if (status && status === 1) {
                dispatch(authSlice.actions.hasSuccess(message));
            }
        } catch (error) {
            dispatch(authSlice.actions.hasButtonState(Date.now()))
            dispatch(authSlice.actions.hasStatus(0))
            dispatch(authSlice.actions.hasError(error.message));
        }
    }
}

export const userLogout = (apiUrl, data) => {
    return async () => {
        try {
            const resp = await axiosServices.post(apiUrl, data);
            const { status, message } = resp.data;
            dispatch(authSlice.actions.hasStatus(status))
            if (status && status === 1) {
                dispatch(authSlice.actions.setActionState('logout'));
                dispatch(authSlice.actions.hasSuccess(message));
            } else {
                dispatch(authSlice.actions.hasError(message));
            }
        } catch (error) {
            dispatch(authSlice.actions.hasError(error.message));
        }
    }
}

export const getGeneralSettings = (apiUrl) => {
    return async () => {
        try {
            const resp = await axiosServices.get(apiUrl, {});
            const { status, response } = resp.data;
            dispatch(authSlice.actions.hasStatus(status))
            if (status && status === 1) {
                dispatch(authSlice.actions.setGeneralSettings(response));
            }
        } catch (error) {
            dispatch(authSlice.actions.hasError(error.message));
        }
    }
}

export const clearUser = () => {
    return async () => {
        dispatch(authSlice.actions.setActionState(''));
        // dispatch(authSlice.actions.setProfile({}));
        // dispatch(authSlice.actions.userStorage({}));
    }
}