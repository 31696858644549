import React, { useState, useEffect, useMemo } from 'react'
import { FormControl, Autocomplete, TextField, MenuItem, FormHelperText, Box } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { GlobalStyles } from '@mui/system';
import PhoneInput from "react-phone-input-2";
import { openSnackbar } from '../store/snackbar';
import DatePicker from "react-multi-date-picker";
import Background from "../assets/img/frame-bg.png"
import calender from '../assets/img/calendar-solid1.png';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import parsePhoneNumber from 'libphonenumber-js'
import { PartnerSchema } from '../utils/validation-schema'
import { dispatch, useSelector } from '../store';
import { partnerAPICall, clearMessages } from '../store/partner';
import './index.css';
import SuccessModal from './sucess-modal';
import "react-phone-input-2/lib/style.css";


const storeLocations = [
	{ id: 1, 'value': '1-10', label: "1 to 10" },
	{ id: 2, 'value': '10-25', label: "10 to 25" },
	{ id: 3, 'value': '25-50', label: "25 to 50" },
	{ id: 4, 'value': '50-100', label: "50 to 100" },
	{ id: 5, 'value': '100', label: "100+" }
]
const ALPHA_REGEX = /^[a-zA-Z]/;

const Partner = () => {

	const defaultValues = useMemo(() => ({
		first_name: '',
		last_name: '',
		dob: new Date(),
		companyName: '',
		businessYears: 0,
		country: {},
		statename: {},
		cityname: {},
		storeAddress: '',
		cityPopulation: 0,
		officeCount: '1-10',
		employeeCount: '1-10',
		phone: '',
		email: '',
		countryRes: 1,
		summary: "",
		briefExplanation: ""
	}), []);

	const methods = useForm({ resolver: yupResolver(PartnerSchema), defaultValues });
	const { watch, reset, handleSubmit, control, setValue } = methods;
	const values = watch();
	const { respStatus, errorMessage, successMessage, actionState, buttonState, locationDetails, stateCityJson } = useSelector((state) => state.partner);
	const [disabledButton, setDisabledButton] = useState(false);
	const [verifyModalPopup, setVerifyModalPopup] = useState(false);
	const [countriesArr, setCountriesArr] = useState([]);
	const [countryDetails, setCountryDetails] = useState(null);
	const [stateArr, setStateArr] = useState([]);
	const [stateDetails, setStateDetails] = useState(null);
	const [cityArr, setCityArr] = useState([]);

	const handleClose = () => {
		setVerifyModalPopup(false)
	}

	const onSubmit = handleSubmit((data = {}) => {
		let formData = new FormData();
		const extractNumber = parsePhoneNumber(data?.phone);
		formData.append('first_name', data.first_name || '');
		formData.append('last_name', data.last_name || '');
		formData.append('dob', data.dob || '');
		formData.append('companyName', data.companyName || '');
		formData.append('businessYears', Number(data.businessYears) || 0);
		formData.append('storeAddress', data.storeAddress || '');
		formData.append('cityPopulation', Number(data.cityPopulation) || 0);
		formData.append('officeCount', data.officeCount || '');
		formData.append('employeeCount', data.employeeCount || '');
		formData.append('email', data.email || '');
		formData.append('summary', data.summary || '');
		formData.append('briefExplanation', data.briefExplanation || '');
		formData.append('countryDetails', JSON.stringify(data.country) || '');
		formData.append('stateDetails', JSON.stringify(data.statename) || '');
		formData.append('cityDetails', JSON.stringify(data.cityname) || '');
		if (extractNumber && Object.keys(extractNumber).length > 0) {
			formData.append('phoneDetails', JSON.stringify({ 'countryCode': extractNumber?.country, 'nationalNumber': extractNumber?.nationalNumber, 'numberValue': extractNumber?.number, 'countryCallingCode': extractNumber?.countryCallingCode }));
		}
		setDisabledButton(true)
		dispatch(partnerAPICall(`user/site/register/partner`, `POST`, `partner-register`, formData))
	})

	useEffect(() => {
		dispatch(partnerAPICall(`user/site/location-details`, `GET`, `location-details`, {}))
	}, [])

	useEffect(() => {
		setStateArr([]);
		setCityArr([]);
		setValue('statename', {})
		setValue('cityname', {})
		setValue('countryRes', countryDetails?.locationRest)
		if (countryDetails?.locationRest === 3) {
			getCityData(countryDetails?._id, 'no-state');
			return;
		}
		if (countryDetails?._id) {
			dispatch(partnerAPICall(`user/site/state-city-lists?id=${countryDetails._id}&actionType=state`, `GET`, `location-state-lists`, {}));
		}
	}, [countryDetails, setValue])

	useEffect(() => {
		setCityArr([]);
		setValue('cityname', {})
		if (countryDetails?.locationRest === 2) {
			return;
		}
		getCityData(stateDetails?._id, 'city');
	}, [stateDetails, countryDetails, setValue])

	const getCityData = (id, actionType) => {
		if (id) {
			dispatch(partnerAPICall(`user/site/state-city-lists?id=${id}&actionType=${actionType}`, `GET`, `location-city-lists`, {}));
		}
	}

	useEffect(() => {
		if (actionState === 'partner-register') {
			setDisabledButton(false)
		}
		if (actionState === 'location-details') {
			setCountriesArr(locationDetails)
		}
		if (actionState === 'location-state-lists') {
			setStateArr(stateCityJson.map((e) => { return { 'label': e.locationname, '_id': e._id, 'locationId': e.locationId, 'countryId': e.countryId } }));
		}
		if (actionState === 'location-city-lists') {
			setCityArr(stateCityJson.map((e) => { return { 'label': e.locationname, '_id': e._id, 'locationId': e.locationId, 'countryId': e.countryId, 'stateId': e.stateId } }));
		}
		if (successMessage !== null && respStatus === 1) {
			if (actionState && actionState === 'partner-register') {
				reset();
				const body = document.querySelector('#root');
				body.scrollIntoView({
					behavior: 'smooth'
				}, 500)
				setVerifyModalPopup(true)
			}
			dispatch(clearMessages())
		} else if (errorMessage !== null) {
			dispatch(
				openSnackbar({
					open: true,
					message: errorMessage,
					variant: 'alert',
					alert: {
						color: 'error'
					},
					close: true
				})
			);
			dispatch(clearMessages())
			if (actionState === 'location-state-lists') {
				setStateArr([]);
			}
			if (actionState === 'location-city-lists') {
				setCityArr([]);
			}
		}
	}, [actionState, buttonState, respStatus, errorMessage, successMessage, stateCityJson, locationDetails, reset])

	return (
		<div className="pt-[65px] min-h-screen bg-[#f3f2f7]">
			<div className="relative">
				<div className="absolute custom-bg-size bg-no-repeat custom-bg-position inset-0 opacity-30" style={{ backgroundImage: `url(${Background})` }}>  </div>
				<div className="relative w-full h-auto flex justify-center">
					<div className="container 2xl:max-w-[1280px] 3xl:max-w-[1536px]  static bg-[#000] opacity-[0.70] p-[30px] md:p-[50px] sm:w-[87%] md:w-[92%] lg:w-[97%] 4xl:w-[98%] lg:mt-[-0.15rem] lg:mt-[-0.11rem]  mt-[0.7rem] md:mt-[0.7rem] rounded-b-[15px] min-h-[500px]">
						<div className="relative z-10">
							<div className="mb-8 text-center">
								<div className="text-3xl md:text-3xl font-semibold">Satellite Partner Registration</div>
								<div className="text-sm md:text-lg text-[#f6e2b9] mt-[12px]">
									Nice to meet you! Enter your details to register.

								</div>
							</div>
							<div className="flex flex-col items-center justify-center min-h-screen">
								<div className="w-full max-w-[55rem] border border-[#999999] rounded-3xl p-4 md:p-8">
									<GlobalStyles
										styles={{
											'input:-webkit-autofill': {
												WebkitBoxShadow: '0 0 0 100px #fff inset !important',
												WebkitTextFillColor: '#000 !important',
												caretColor: '#000 !important',
												borderRadius: '0.5rem',
											},
										}}
									/>
									<form onSubmit={handleSubmit(onSubmit)} autoComplete='off'>
										<div className="grid gap-6 mb-6 md:grid-cols-2">
											<FormControl>
												<label htmlFor="first_name" className="block mb-2 text-sm font-medium text-[#fff]">
													First Name
												</label>
												<Controller
													name="first_name"
													control={control}
													render={({ field, fieldState: { error } }) => (
														<TextField
															{...field}
															size="small"
															placeholder="John"
															value={field.value}
															onChange={(event) => field.onChange(event.target.value)}
															helperText={error ? error?.message : ""}
															error={!!error}
															slotProps={{
																input: { className: "!bg-[#fff] border border-gray-300 !text-[#000] text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full" }
															}}
														/>
													)}
												/>
											</FormControl>
											<FormControl>
												<label htmlFor="last_name" className="block mb-2 text-sm font-medium text-[#fff]">
													Last Name
												</label>
												<Controller
													name="last_name"
													control={control}
													render={({ field, fieldState: { error } }) => (
														<TextField
															{...field}
															size="small"
															placeholder="Doe"
															value={field.value}
															onChange={(event) => field.onChange(event.target.value)}
															helperText={error ? error?.message : ""}
															error={!!error}
															slotProps={{
																input: { className: "!bg-[#fff] border border-gray-300 !text-[#000] text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full" }
															}}
														/>
													)}
												/>
											</FormControl>
										</div>
										<div className="grid gap-6 mb-6 md:grid-cols-2">
											<FormControl>
												<label htmlFor="email" className="block mb-2 text-sm font-medium text-[#fff]">
													Phone Number
												</label>
												<Controller
													control={control}
													name='phone'
													render={({ field, fieldState }) => (
														<Box className="phone-country-profile">
															<PhoneInput
																country={"us"}
																value={field?.value}
																onChange={(value, country, e, formattedValue) => {
																	field?.onChange(formattedValue)
																}}
																countryCodeEditable={false}
																enableSearch={true}
																disableSearchIcon={true}
																searchClass="phone-input-search-profile"
																inputClass='phone-dropdown-profile'
																sx={{
																	'& .MuiOutlinedInput-input': {
																		color: '#000',
																	},
																	'& .MuiInputLabel-root': {
																		color: '#000',
																	},
																}}
																className="!bg-[#fff] border border-gray-300 text-[#000] text-sm rounded-[0.2rem] focus:ring-blue-500 focus:border-blue-500 block w-full"

															/>
															{fieldState.invalid ? (<FormHelperText error>{fieldState?.error?.message}</FormHelperText>) : null}
														</Box>
													)}
												/>
											</FormControl>
											<FormControl>
												<label htmlFor="email" className="block mb-2 text-sm font-medium text-[#fff]">
													Email Id
												</label>
												<Controller
													name="email"
													control={control}
													render={({ field, fieldState: { error } }) => (
														<TextField
															{...field}
															size="small"
															placeholder="johndoe@company.com"
															value={field.value}
															onChange={(event) => field.onChange(event.target.value)}
															helperText={error ? error?.message : ""}
															error={!!error}
															slotProps={{
																input: { className: "!bg-[#fff] border border-gray-300 !text-[#000] text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full" }
															}}
														/>
													)}
												/>
											</FormControl>
										</div>
										<div className="grid gap-6 mb-6 md:grid-cols-2">
											<FormControl>
												<label htmlFor="dob" className="block mb-2 text-sm font-medium text-[#fff]">
													Date of Birth
												</label>
												<div className="relative">
													<div className="absolute mt-[0.5em] flex items-center pl-3 pointer-events-none">
														<img src={calender} alt="calendar" />
													</div>
													<Controller
														control={control}
														name="dob"
														render={({ field: { onChange, name, value }, formState: { errors } }) => (
															<>
																<DatePicker
																	value={value || ""}
																	onChange={(date, { input, isTyping }) => {
																		if (isTyping) return false;
																		onChange(date?.isValid ? date : "")

																	}}
																	inputClass="bg-[#fff] w-[100%] border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block pl-10 pr-2.5 py-2.5"
																/>
																{errors[name] && <FormHelperText error>{errors[name].message}</FormHelperText>}
															</>
														)}
													/>
												</div>
											</FormControl>
											<FormControl>
												<label htmlFor="companyName" className="block mb-2 text-sm font-medium text-[#fff]">
													Name of Business
												</label>
												<Controller
													name="companyName"
													control={control}
													render={({ field, fieldState: { error } }) => (
														<TextField
															{...field}
															size="small"
															placeholder="Enter company name"
															value={field.value}
															onChange={(event) => field.onChange(event.target.value)}
															helperText={error ? error?.message : ""}
															error={!!error}
															slotProps={{
																input: { className: "!bg-[#fff] border border-gray-300 !text-[#000] text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full" }
															}}
														/>
													)}
												/>
											</FormControl>
										</div>
										<div className="grid gap-6 mb-6 md:grid-cols-3">
											{countriesArr?.length > 0 && (
												<FormControl>
													<label htmlFor="country" className="block mb-2 text-sm font-medium text-[#fff]">
														Country
													</label>
													<Controller
														name="country"
														control={control}
														render={({ field, fieldState: { error } }) => (
															<>
																<Autocomplete
																	{...field}
																	size="small"
																	options={countriesArr}
																	disableClearable={true}
																	getOptionLabel={(option) => option.label || ''}
																	renderOption={(props, option) => (
																		<li {...props} key={option._id}>
																			{option.label || ''}
																		</li>
																	)}
																	isOptionEqualToValue={(option, value) => option._id === value?._id}
																	renderInput={(params) => (
																		<TextField
																			{...params}
																			placeholder="Choose a country"
																			helperText={error?.label?.message || error?.message}
																			slotProps={{
																				label: { sx: { color: '#000' } },
																				formHelperText: { style: { color: '#e35858' } },
																				input: {
																					...params.InputProps,
																					classes: {
																						input: 'text-black',
																						inputAdornedStart: 'text-black',
																					},
																					className:
																						"!bg-[#fff] border border-gray-300 !text-[#000] text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5",
																				},
																			}}
																			sx={{
																				'& .MuiInputBase-input': {
																					color: '#000',
																				},
																				'& .MuiInputBase-input::placeholder': {
																					color: '#000',
																				},
																			}}
																			onKeyDown={(event) => {
																				if (!ALPHA_REGEX.test(event.key)) {
																					event.preventDefault();
																				}
																			}}
																		/>

																	)}
																	onChange={(_, data) => {
																		field.onChange(data);
																		const index = countriesArr.findIndex(e => e._id === data?._id);
																		if (index !== -1) {
																			setCountryDetails(countriesArr[index]);
																		}
																	}}
																/>
																{error && <FormHelperText style={{ color: '#e35858' }}>{error.message}</FormHelperText>}
															</>
														)}
													/>
												</FormControl>
											)}
											{values?.countryRes !== 3 && (
												<FormControl>
													<label htmlFor="statename" className="block mb-2 text-sm font-medium text-[#fff]">
														State
													</label>
													<Controller
														name="statename"
														control={control}
														render={({ field, fieldState: { error } }) => (
															<>
																<Autocomplete
																	{...field}
																	size="small"
																	options={stateArr}
																	disableClearable={true}
																	getOptionLabel={(option) => option.label || ''}
																	renderOption={(props, option) => (
																		<li {...props} key={option._id}>
																			{option.label || ''}
																		</li>
																	)}
																	isOptionEqualToValue={(option, value) => option._id === value?._id}
																	renderInput={(params) => (
																		<TextField
																			{...params}
																			placeholder="Choose a state"
																			helperText={error?.label?.message || error?.message}
																			slotProps={{
																				input: {
																					...params.InputProps,
																					classes: {
																						input: 'text-black',
																						inputAdornedStart: 'text-black',
																					},
																					className:
																						"!bg-[#fff] border border-gray-300 !text-[#000] text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5",
																				},
																				formHelperText: {
																					sx: { color: '#e35858' },
																				},
																				label: {
																					sx: { color: '#000' },
																				},
																			}}
																			sx={{
																				'& .MuiInputBase-input': {
																					color: '#000',
																				},
																				'& .MuiInputBase-input::placeholder': {
																					color: '#000',
																				},
																			}}
																			onKeyDown={(event) => {
																				if (!ALPHA_REGEX.test(event.key)) {
																					event.preventDefault();
																				}
																			}}
																		/>

																	)}
																	onChange={(_, data) => {
																		field.onChange(data);
																		const index = stateArr.findIndex(e => e._id === data?._id);
																		if (index !== -1) {
																			setStateDetails(stateArr[index]);
																		}
																	}}
																/>
																{error && <FormHelperText style={{ color: '#e35858' }}>{error.message}</FormHelperText>}
															</>
														)}
													/>
												</FormControl>
											)}
											{values?.countryRes !== 2 && (
												<FormControl>
													<label htmlFor="cityname" className="block mb-2 text-sm font-medium text-[#fff]">
														City
													</label>
													<Controller
														name="cityname"
														control={control}
														render={({ field, fieldState: { error } }) => (
															<>
																<Autocomplete
																	{...field}
																	size="small"
																	options={cityArr}
																	disableClearable={true}
																	getOptionLabel={(option) => option.label || ''}
																	renderOption={(props, option) => (
																		<li {...props} key={option._id}>
																			{option.label || ''}
																		</li>
																	)}
																	isOptionEqualToValue={(option, value) => option._id === value?._id}
																	renderInput={(params) => (
																		<TextField
																			{...params}
																			placeholder="Choose a city"
																			helperText={error?.label?.message || error?.message}
																			slotProps={{
																				input: {
																					...params.InputProps,
																					classes: {
																						input: 'text-black',
																						inputAdornedStart: 'text-black',
																					},
																					className:
																						"!bg-[#fff] border border-gray-300 !text-[#000] text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5",
																				},
																				formHelperText: {
																					sx: { color: '#e35858' },
																				},
																				label: {
																					sx: { color: '#000' },
																				},
																			}}
																			sx={{
																				'& .MuiInputBase-input': {
																					color: '#000',
																				},
																				'& .MuiInputBase-input::placeholder': {
																					color: '#000',
																				},
																			}}

																			onKeyDown={(event) => {
																				if (!ALPHA_REGEX.test(event.key)) {
																					event.preventDefault();
																				}
																			}}
																		/>
																	)}
																	onChange={(_, data) => {
																		field.onChange(data);
																	}}
																/>

																{error && <FormHelperText style={{ color: '#e35858' }}>{error.message}</FormHelperText>}
															</>
														)}
													/>
												</FormControl>
											)}
										</div>
										<div className="grid gap-6 mb-6 md:grid-cols-1">
											<div className="grid col-span-1">
												<FormControl>
													<label htmlFor="storeAddress" className="block mb-2 text-sm font-medium text-[#fff]">
														Main Office or Store Address
													</label>
													<Controller
														name="storeAddress"
														control={control}
														render={({ field, fieldState: { error } }) => (
															<TextField
																{...field}
																size="small"
																value={field.value}
																onChange={(event) => field.onChange(event.target.value)}
																placeholder="Enter the office or store address"
																helperText={error ? error?.message : ""}
																error={!!error}
																slotProps={{
																	input: { className: "!bg-[#fff] border border-gray-300 !text-[#000] text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full" }
																}}
															/>
														)}
													/>
												</FormControl>
											</div>
										</div>
										<div className="grid gap-6 mb-6 md:grid-cols-2">
											<FormControl>
												<label htmlFor="cityPopulation" className="block mb-2 text-sm font-medium text-[#fff]">
													City Population
												</label>
												<Controller
													name="cityPopulation"
													control={control}
													render={({ field, fieldState: { error } }) => (
														<TextField
															{...field}
															size="small"
															value={field.value === 0 ? '' : field.value}
															onChange={(event) => {
																const newValue = Number(event.target.value);
																if (newValue >= 0) {
																	field.onChange(newValue);
																} else {
																	field.onChange(0);
																}
															}}
															placeholder="eg:38222"
															type="number"
															helperText={error ? error?.message : ""}
															error={!!error}
															slotProps={{
																input: {
																	className: "!bg-[#fff] border border-gray-300 !text-[#000] text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full"
																}
															}}
														/>
													)}
												/>

											</FormControl>
											<FormControl>
												<label htmlFor="businessYears" className="block mb-2 text-sm font-medium text-[#fff]">
													Years in Business
												</label>
												<Controller
													name="businessYears"
													control={control}
													render={({ field, fieldState: { error } }) => (
														<TextField
															{...field}
															size="small"
															value={field.value === 0 ? '' : field.value}
															onChange={(event) => {
																const newValue = Number(event.target.value);
																if (newValue >= 0) {
																	field.onChange(newValue);
																} else {
																	field.onChange(0);
																}
															}}
															placeholder="eg:10"
															type="number"
															helperText={error ? error?.message : ""}
															error={!!error}
															slotProps={{
																input: { className: "!bg-[#fff] border border-gray-300 !text-[#000] text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full" }
															}}
														/>
													)}
												/>
											</FormControl>
										</div>
										<div className="grid gap-6 mb-6 md:grid-cols-2">
											<FormControl>
												<label htmlFor="officeCount" className="block mb-2 text-sm font-medium text-[#fff]">
													Number of office/store locations
												</label>
												<Controller
													name="officeCount"
													control={control}
													render={({ field, fieldState: { error } }) => (
														<TextField
															select
															{...field}
															size="small"
															value={field.value}
															onChange={(event) => field.onChange(event.target.value)}
															helperText={error ? error?.message : ""}
															error={!!error}
															slotProps={{
																input: {
																	className: "custom-select !bg-[#fff] border border-gray-300 !text-[#000] text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full",
																	'aria-label': 'Number of office/store locations'
																}
															}}
															SelectProps={{
																MenuProps: {
																	disableScrollLock: true,
																	disablePortal: true
																}
															}}
														>
															{storeLocations?.map((option) => (
																<MenuItem key={option.id} value={option.value}>
																	{option.label}
																</MenuItem>
															))}
														</TextField>
													)}
												/>
											</FormControl>



											<FormControl>
												<label htmlFor="employeeCount" className="block mb-2 text-sm font-medium text-[#fff]">
													Number of employees
												</label>
												<Controller
													name="employeeCount"
													control={control}
													render={({ field, fieldState: { error } }) => (
														<TextField
															select
															{...field}
															size="small"
															value={field.value}
															onChange={(event) => field.onChange(event.target.value)}
															helperText={error ? error?.message : ""}
															error={!!error}
															slotProps={{
																input: {
																	className: "custom-select !bg-[#fff] border border-gray-300 !text-[#000] text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full",
																	'aria-label': 'Number of office/store locations'
																}
															}}
															SelectProps={{
																MenuProps: {
																	disableScrollLock: true,
																	disablePortal: true
																}
															}}
														>
															{storeLocations?.map((option) => (
																<MenuItem key={option.id} value={option.value}>
																	{option.label}
																</MenuItem>
															))}
														</TextField>
													)}
												/>
											</FormControl>

										</div>
										<div className="grid gap-6 mb-6 md:grid-cols-1">
											<FormControl>
												<label htmlFor="summary" className="block mb-2 text-sm font-medium text-[#fff]">
													Business Experience and Background
												</label>
												<Controller
													name="summary"
													control={control}
													render={({ field, fieldState: { error } }) => (
														<TextField
															{...field}
															multiline
															rows={3}
															size="small"
															className="custom-summary"
															value={field.value}
															onChange={(event) => {
																const newValue = event.target.value;
																if (newValue.length <= 2506) {
																	field.onChange(newValue);
																}
															}}
															placeholder="Write a summary"
															helperText={error ? error?.message : ""}
															error={!!error}
															slotProps={{
																input: {
																	maxLength: 2506,
																	className: "!bg-[#fff] border border-gray-300 !text-[#000] text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full"
																}
															}}
														/>
													)}
												/>

											</FormControl>
										</div>
										<div className="grid gap-6 mb-6 md:grid-cols-1">
											<FormControl>
												<label htmlFor="briefExplanation" className="block mb-2 text-sm font-medium text-[#fff]">
													Why you would be the best candidate to be selected as a partner?
												</label>
												<Controller
													name="briefExplanation"
													control={control}
													render={({ field, fieldState: { error } }) => (
														<TextField
															{...field}
															multiline
															rows={3}
															size="small"
															className="custom-summary"
															value={field.value}
															onChange={(event) => field.onChange(event.target.value)}
															placeholder="Write a brief explanation"
															helperText={error ? error?.message : ""}
															error={!!error}
															slotProps={{
																input: { className: "!bg-[#fff] border border-gray-300 !text-[#000] text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full" }
															}}
														/>
													)}
												/>
											</FormControl>
										</div>
										<LoadingButton
											variant="contained"
											type="submit"
											loading={disabledButton}
											sx={{
												width: '100%',
												maxWidth: '200px',
												margin: 'auto',
												display: 'flex',
												justifyContent: 'center',
												alignItems: 'center',
												marginTop: '3rem',
												backgroundColor: '#1976D1',
												color: '#fff',
												borderRadius: '32px'
											}}
										>
											Submit
										</LoadingButton>
									</form>
									{verifyModalPopup && verifyModalPopup === true ? <SuccessModal verifyModalShow={verifyModalPopup} handleClose={handleClose} /> : null}
								</div>
							</div>
						</div>
					</div>

				</div>
			</div>
		</div>
	)
}

export default Partner