import { useEffect } from "react";
import { useLocation } from "react-router";
import PropTypes from 'prop-types';

const ScrollToTop = ({ children }) => {
    const location = useLocation();
    const body = document.querySelector('#root');
    useEffect(() => {
        body.scrollIntoView({
            top: 0,
            left: 0,
			behavior: 'smooth'
		}, 500)
    }, [location.pathname, body]);
    return children || null;
};

ScrollToTop.propTypes = {
    children: PropTypes.node
  };
  
export default ScrollToTop;