import { createSlice } from '@reduxjs/toolkit';
import axiosServices from '../utils/axios';
import { dispatch } from './index';

const initialState = {
    errorMessage: null,
    successMessage: null,
    respStatus: 0
};

const contactSlice = createSlice({
    name: 'contact',
    initialState,
    reducers: {
        hasError(state, action) {
            state.errorMessage = action.payload;
        },
        hasSuccess(state, action) {
            state.successMessage = action.payload;
        },
        hasStatus(state, action) {
            state.respStatus = action.payload;
        }
    }
});

export default contactSlice.reducer;
export const saveContact = (apiUrl, data) => {
    return async () => {
        try {
            const resp = await axiosServices.post(apiUrl, data);
            const { status, message} = resp.data;
            dispatch(contactSlice.actions.hasStatus(status))
            if (status && status === 1) {
                dispatch(contactSlice.actions.hasSuccess(message));
            }
        } catch (error) {
            dispatch(contactSlice.actions.hasError(error.message));
        }
    }
}