import * as yup from 'yup'
import { matchIsValidTel } from 'mui-tel-input'
import { differenceInYears } from 'date-fns';

const REQUIRED_ERROR_MESSAGE = `Please enter the phone number`;
const INVALID_PHONE_NUMBER_ERROR_MESSAGE = `Please enter the phone number`;

function checkPhoneNumber(message) {
    return this.test("isValidPhoneNumber", message, function (value) {
        const { path, createError } = this;
        if (!value) {
            return createError({ path, message: message ?? REQUIRED_ERROR_MESSAGE });
        }
        let isValid = matchIsValidTel(value);
        if (isValid === false) {
            return createError({ path, message: message ?? INVALID_PHONE_NUMBER_ERROR_MESSAGE });
        }
        return true;
    });
}
yup.addMethod(yup.mixed, "checkPhoneNumber", checkPhoneNumber);

const PartnerSchema = yup.object().shape({
    first_name: yup.string().matches(/^[A-Za-z ]*$/, 'Firstname can only contain Alphabetical letters').max(40, 'Firstname must be less than 40 characters!').required('Please enter your first name'),
    last_name: yup.string().matches(/^[A-Za-z ]*$/, 'Lastname can only contain Alphabetical letters').max(40, 'Lastname must be less than 50 characters!').required('Please enter your last name'),
    companyName: yup.string().required('Please enter the company name'),
    businessYears: yup.number().required('This is required field').moreThan(0, "Business Years must be greater than or equal to 1!"),
    country: yup.object().shape({
        label: yup.string().required("Please select the country"),
    }).required("Please select the country"),
    cityname: yup.mixed().when("countryRes", (countryRes) => {
        if (countryRes[0] && Number(countryRes[0]) !== 2) {
            return yup.object().shape({
                label: yup.string().required("Please select the city"),
            }).required("Please select the city")
        }
    }),
    statename: yup.mixed().when("countryRes", (countryRes) => {
        if (countryRes[0] && Number(countryRes[0]) !== 3) {
            return yup.object().shape({
                label: yup.string().required("Please select the state"),
            }).required("Please select the state")
        }
    }),
    storeAddress: yup.string().required('Please enter the store address'),
    cityPopulation: yup.number().required('This is required field').moreThan(0, "City population must be greater than or equal to 1!"),
    officeCount: yup.string().required('Please select the office count'),
    employeeCount: yup.string().required('Please select the employee count'),
    phone: yup.mixed().checkPhoneNumber(),
    email: yup.string().email('Invalid email address').required('Please enter the email address'),
    dob: yup.date()
        .required('Please select the DOB')
        .test('dob', 'You must be at least 18 years old', function (value) {
            return differenceInYears(new Date(), new Date(value)) >= 18;
        }),
    briefExplanation: yup.string().required('Please enter the brief explanation'),
    summary: yup.string().required('Please enter the business experience and background')
})

const ContactSchema = yup.object().shape({
    first_name: yup.string().matches(/^[A-Za-z ]*$/, 'Firstname can only contain Alphabetical letters').max(40, 'Firstname must be less than 40 characters!').required('Please enter your first name'),
    last_name: yup.string().matches(/^[A-Za-z ]*$/, 'Lastname can only contain Alphabetical letters').max(40, 'Lastname must be less than 50 characters!').required('Please enter your last name'),
    email: yup.string().email('Invalid email address').required('This is required field'),
    phone: yup.string().matches(/^[+]?(\d{1,2})?[\s.-]?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/, 'Phone number is invalid').required('This is required field'),
    message: yup.string().required('This is required field')
});

const MarketResearchSchema = yup.object().shape({
    name: yup.string().matches(/^[A-Za-z ]*$/, 'Name can only contain Alphabetical letters').max(40, 'Name must be less than 40 characters!').required('Please enter your Name'),
    reason: yup.string().required('This is required field'),
    title: yup.string().required('This is required field'),
    companyName: yup.string().required('This is required field'),
    companyPhone: yup.mixed().checkPhoneNumber(),
    companyAddress: yup.string().required('This is required field'),
    projectNumber: yup.number().required('This is required field').moreThan(0, "Project number must be greater than or equal to 1!"),
    projectTitle: yup.string().required('This is required field'),
    projectDetails: yup.string().required('This is required field'),
    email: yup.string().email('Invalid email address').required('This is required field'),
    phone: yup.mixed().checkPhoneNumber(),
    launchDate: yup.date().required('This is required field')
});

export {
    PartnerSchema,
    ContactSchema,
    MarketResearchSchema
}

