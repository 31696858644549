

import {React} from 'react';
import Bgimage from '../assets/img/getverified-bg.png';
import Background from "../assets/img/frame-bg.png";
import logo from '../assets/img/Group1.svg';
import video from '../assets/img/Video.png';
import vector from '../assets/img/vector.png';
import google from '../assets/img/Badge.png';
import app from '../assets/img/Group.png';
import animation from '../assets/img/Animation.png';
import icon1 from '../assets/img/content-writing.png';
import icon2 from '../assets/img/content-writing(1).png';
import icon3 from '../assets/img/content-writing(2).png';
import icon4 from '../assets/img/content-writing(3).png';
import icon5 from '../assets/img/content-writing(4).png';
import './index.css';

const GetVerified = () => {


  return (


    <div className="pt-[62px] min-h-screen overflow-x-hidden bg-[#f3f2f7]">
       
<div className="parent-container flex justify-end">
    <div className="bg-no-repeat custom-bg-position custom-adv-bg" 
         style={{ 
             backgroundImage: `url(${Background})`, 
             width: '100%', 
             height: '100%', 
             opacity: '0.10',
             position: 'absolute',
             top: '29%',
             right:'0',
             
         }} 
    />
</div>
<div
  className="background-image md:h-[100vh] h-[33vh] "
  style={{
    backgroundImage: `url(${Bgimage})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    width: '100vw',
  }}
>


<div className="relative z-10 flex flex-col justify-start items-center md:min-h-screen md:justify-center xl:items-start h-auto px-4 sm:px-8 md:px-16 lg:px-24 xl:px-32">
    <h2 className="text-center lg:text-left xl:text-left text-[20px] sm:text-[36px] md:text-[52px] lg:text-[67px] xl:text-[100px] font-semibold mt-[6rem] lg:mt-[16rem]">
        Get Verified & Paid
    </h2>
    <p className="text-center xl:text-left w-full sm:w-[75%] md:w-[67%] lg:w-[50%] text-[10px] sm:text-[14px] md:text-[20px] lg:text-[22px] xl:text-[24px] 5xl:text-[38px] text-[#F4F4F4] mt-1 lg:mt-4">
        Join our community of verified content creators and unlock a world of opportunities with aeedee.
    </p>
    <div className="flex justify-center xl:justify-start w-full mt-[0.5rem] lg:mt-6">
        <button
            type="submit"
            className="text-[#0D0D0D] bg-[#fff] hover:bg-[#808080] rounded-[58px] text-[10px] lg:text-[21px] lg:w-auto w-auto max-w-[256px] h-auto px-3 py-[0.1rem] lg:px-5 lg:py-1.5 text-center">
            Get Verified
        </button>
    </div>
</div>


</div>
        <div className="relative w-full h-auto flex justify-center overflow-x-hidden">
          
          <div className="container 2xl:max-w-[1280px] 3xl:max-w-[1536px]  static bg-[#000] opacity-[1] px-[2px] md:p-[10px] md:w-[90%] lg:w-[97%] lg:mt-[0rem] pt-[1rem] rounded-b-[15px] min-h-[500px]">
          <div className="mx-4 sm:mx-6 md:mx-6 lg:mx-12 grid grid-cols-1 md:grid-cols-2 gap-4 relative h-auto min-h-[426px] px-4">
  <div className="flex flex-col justify-center items-center md:items-start text-center md:text-left">
    <h3 className="w-full tracking-widest text-[18px] sm:text-[22px] md:text-[20px] 5xl:text-[26px] font-semibold text-[#1172BA]">
      GET VERIFIED
    </h3>
    <h2 className="w-full max-w-[713px] text-[20px] sm:text-[30px] md:text-[30px] lg:text-[40px] xl:text-[50px] 5xl:text-[60px] font-bold text-gradient mb-4">
      Unlock Opportunities with AeeDee
    </h2>
    <p className="w-full max-w-full md:max-w-[570px] text-[12px] sm:text-[14px] md:text-[16px] 5xl:text-[24px] text-[#F4F4F4] font-normal">
      Join our community of verified content creators and unlock a world of opportunities with AeeDee. Here's how:
    </p>
  </div>
  <div className="relative w-full max-w-full md:max-w-[60rem] lg:max-w-[80rem] 5xl:max-w-[50rem] h-auto flex items-center justify-center">
  <img
    src={video}
    alt="video"
    className="w-full md:w-full lg:w-full h-auto md:h-auto lg:h-auto object-cover"
  />
</div>

</div>



<div className="mx-[2rem] grid grid-cols-1 md:grid-cols-1 lg:grid-cols-1 gap-4 bg-[#000]">
<div className="flex flex-col md:flex-row items-center md:items-start md:gap-8 lg:mt-8 mt-1 mb-6">
  <img src={logo} alt="logo" className="mb-4 w-24 h-24 md:w-32 md:h-32" />
  <h3 className="w-full max-w-[595px] 4xl:max-w-[640px] text-center md:text-left text-[20px] sm:text-[30px] md:text-[40px] lg:text-[40px] xl:text-[50px] 5xl:text-[58px] font-semibold text-gradient">
  Unleash Your Potential with AeeDee
  </h3>
</div>




              <div className="mx-auto px-[1px] xl:mx-0 xl:px-[0px] grid grid-cols-1 md:grid-cols-2 md:items-start gap-8">
      <div className="flex flex-col items-center md:items-start lg:mb-6 mb-1">
        <div className="flex items-center mb-3">
        <div className="relative group">
        <div className="absolute inset-0 transition duration-300 ease-in-out"></div>
        <img src={icon1} alt="icon5" className="mr-3 w-8 h-8 xl:w-16 xl:h-16 transform transition duration-300 ease-in-out group-hover:scale-125" />
    </div>  
        <h4 className="text-[16px] sm:text-[18px] md:text-[20px] lg:text-[22px] 5xl:text-[28px] font-semibold text-[#fff]">
            Verification Process
          </h4>
        </div>
        <p className="text-[14px] sm:text-[15px] md:text-[16px] lg:text-[18px] 5xl:text-[24px] text-[#F4F4F4] font-medium text-center md:text-left">
          Lorem ipsum is a placeholder text without relying on meaningful content.
        </p>
      </div>
      <div className="flex flex-col items-center md:items-start lg:mb-6 mb-1">
        <div className="flex items-center mb-3">
        <div className="relative group">
        <div className="absolute inset-0 transition duration-300 ease-in-out"></div>
        <img src={icon2} alt="icon5" className="mr-3 w-8 h-8 xl:w-16 xl:h-16 transform transition duration-300 ease-in-out group-hover:scale-125" />
    </div>             <h4 className="text-[16px] sm:text-[18px] md:text-[20px] lg:text-[22px]  5xl:text-[28px] font-semibold text-[#fff]">
            Become a Content Creator
          </h4>
        </div>
        <p className="text-[14px] sm:text-[15px] md:text-[16px] lg:text-[18px] 5xl:text-[24px] text-[#F4F4F4] font-medium text-center md:text-left">
          Lorem ipsum is a placeholder text without relying on meaningful content.
        </p>
      </div>
      <div className="flex flex-col items-center md:items-start lg:mb-6 mb-1">
        <div className="flex items-center mb-3">
        <div className="relative rounded-full border-2 border-transparent group-hover:border-blue-500 transition duration-300 ease-in-out">
        
        <div className="relative group">
        <div className="absolute inset-0 transition duration-300 ease-in-out"></div>
        <img src={icon3} alt="icon5" className="mr-3 w-8 h-8 xl:w-16 xl:h-16 transform transition duration-300 ease-in-out group-hover:scale-125" />
    </div>             </div>
          <h4 className="text-[16px] sm:text-[18px] md:text-[20px] lg:text-[22px] 5xl:text-[28px] font-semibold text-[#fff]">
            Monetize Your Content
          </h4>
          
        </div>
        <p className="text-[14px] sm:text-[15px] md:text-[16px] lg:text-[18px] 5xl:text-[24px] text-[#F4F4F4] font-medium text-center md:text-left">
          Lorem ipsum is a placeholder text without relying on meaningful content.
        </p>
      </div>
      <div className="flex flex-col items-center md:items-start lg:mb-6 mb-1">
        <div className="flex items-center mb-3">
        <div className="relative group">
        <div className="absolute inset-0 transition duration-300 ease-in-out"></div>
        <img src={icon4} alt="icon5" className="mr-3 w-8 h-8 xl:w-16 xl:h-16 transform transition duration-300 ease-in-out group-hover:scale-125" />
    </div>             <h4 className="text-[16px] sm:text-[18px] md:text-[20px] lg:text-[22px] 5xl:text-[28px]  font-semibold text-[#fff]">
            Access Exclusive Benefits
          </h4>
        </div>
        <p className="text-[14px] sm:text-[15px] md:text-[16px] lg:text-[18px] 5xl:text-[24px] text-[#F4F4F4] font-medium text-center md:text-left">
          Lorem ipsum is a placeholder text without relying on meaningful content.
        </p>
      </div>
      <div className="flex flex-col items-center md:items-start lg:mb-6 mb-1">
        <div className="flex items-center mb-3">
        <div className="relative group">
        <div className="absolute inset-0 transition duration-300 ease-in-out"></div>
        <img src={icon5} alt="icon5" className="mr-3 w-8 h-8 xl:w-16 xl:h-16 transform transition duration-300 ease-in-out group-hover:scale-125" />
    </div>          <h4 className="text-[16px] sm:text-[18px] md:text-[20px] lg:text-[22px] 5xl:text-[28px] font-semibold text-[#fff]">
            Grow Your Business
          </h4>
        </div>
        <p className="text-[14px] sm:text-[15px] md:text-[16px] lg:text-[18px] 5xl:text-[24px] text-[#F4F4F4] font-medium text-center md:text-left">
          Lorem ipsum is a placeholder text without relying on meaningful content.
        </p>
      </div>
    </div> 
  </div>

           


         
  <div className="relative flex flex-col justify-center items-center px-4 py-8 lg:mt-[1rem] md:mt-[2rem] lg:mb-[10rem] sm:mb-[0rem]">
  <div className="relative flex flex-col justify-center items-center w-full max-w-screen-lg border rounded-2xl p-2 z-40 bg-[#000]">
    <div className="grid grid-cols-1 md:grid-cols-2 gap-6 items-center md:mt-2">
      <div className="p-4 lg:pl-[4rem] flex flex-col items-center md:items-start">
        <h2 className="w-full max-w-[450px] 4xl:max-w-[500px] text-[24px] sm:text-[30px] md:text-[36px] lg:text-[40px] xl:text-[48px] 5xl:text-[54px] text-gradient font-semibold text-center md:text-left">
          Download AeeDee & Get Verified
        </h2>
        <p className="text-[12px] sm:text-[14px] md:text-[16px] lg:text-[22px] 5xl:text-[26px] font-medium text-[#F4F4F4] text-center md:text-left mt-4">
          Download AeeDee Now! Become a verified user and accept payments as content creators.
        </p>
        <div className="flex space-x-4 mt-4">
          <img src={google} alt="google" className="w-[80px] sm:w-[80px] md:w-[100px] lg:w-[120px] 5xl:w-[190px] h-auto object-contain" />
          <img src={app} alt="app" className="w-[80px] sm:w-[80px] md:w-[100px] lg:w-[120px] 5xl:w-[190px] h-auto object-contain" />
        </div>
      </div>
      <div className="flex justify-center md:justify-end">
        <img src={vector} alt="vector" className="w-[220px] h-[180px] sm:w-[250px] sm:h-[200px] md:w-[300px] md:h-[250px] lg:w-[350px] lg:h-[300px]" />
      </div>
    </div>
  </div>
  <img
    src={animation}
    alt="animation"
    className="hidden lg:block lg:absolute md:right-[-65px] md:bottom-[-7px] lg:right-[-43px] lg:bottom-[-43px] 2xl:right-[25px] 2xl:bottom-[3px] 3xl:right-[107px] 3xl:bottom-[-26px] 4xl:right-[106px] 4xl:bottom-[3px] 5xl:right-[66px] 5xl:bottom-[3px] md:w-[430px] md:h-[430px] lg:w-[500px] lg:h-[500px] 5xl:w-[550px] 5xl:h-[550px] animate-rotateAnimation z-0 scroll-mx-0"
  />
</div>






          </div>
        </div>
      </div>

  )
}

export default GetVerified