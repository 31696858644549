export const abbreviateNumber = (n) => {
  let formatNumber = n;
  if (n < 1e3) {
    formatNumber = n;
  }
  if (n >= 1e3 && n < 1e6) {
    formatNumber = `${+(n / 1e3).toFixed(1)}K`;
  }
  if (n >= 1e6 && n < 1e9) {
    formatNumber = `${+(n / 1e6).toFixed(1)}M`;
  }
  if (n >= 1e9 && n < 1e12) {
    formatNumber = `${+(n / 1e9).toFixed(1)}B`;
  }
  if (n >= 1e12) {
    formatNumber = `${+(n / 1e12).toFixed(1)}T`;
  }
  return formatNumber;
};
