import { createSlice } from '@reduxjs/toolkit';
import axios from '../utils/axios';
import { dispatch } from './index';

const initialState = {
    commonErrorMessage: null,
    commonSuccessMessage: null,
    commonRespStatus: 0,
    commonActionState: '',
    commonButtonState: Date.now(),
    videoLinks: {},
    faqModuleLists: [],
    faqLists: []
};

const commonSlice = createSlice({
    name: 'common',
    initialState,
    reducers: {
        hasError(state, action) {
            state.commonErrorMessage = action.payload;
        },
        hasSuccess(state, action) {
            state.commonSuccessMessage = action.payload;
        },
        hasStatus(state, action) {
            state.commonRespStatus = action.payload;
        },
        setActionState(state, action) {
            state.commonActionState = action.payload
        },
        setButtonState(state, action) {
            state.commonButtonState = action.payload;
        },
        setVideoLinks(state, action) {
            state.videoLinks = action.payload;
        },
        setFaqModuleLists(state, action) {
            state.faqModuleLists = action.payload;
        },
        setFaqLists(state, action) {
            state.faqLists = action.payload;
        },
    }
});

export default commonSlice.reducer;
export const commonAPICall = (apiUrl, method, action, data) => {
    return async () => {
        try {
            let resp;
            if (method === 'GET') {
                resp = await axios.get(apiUrl, data);
            } else if (method === 'POST') {
                resp = await axios.post(apiUrl, data);
            }
            const { status, message, response } = resp.data;
            dispatch(commonSlice.actions.hasStatus(status))
            dispatch(commonSlice.actions.setActionState(action))
            dispatch(commonSlice.actions.setButtonState(Date.now()))
            if (status && status === 1) {
                if (action === 'get-video-links') {
                    return dispatch(commonSlice.actions.setVideoLinks(response));
                } else if (action === 'faq-module-lists') {
                    return dispatch(commonSlice.actions.setFaqModuleLists(response));
                } else if (action === 'faq-lists') {
                    return dispatch(commonSlice.actions.setFaqLists(response));
                }
                dispatch(commonSlice.actions.hasSuccess(message));
            } else {
                dispatch(commonSlice.actions.hasError(message));
            }
        } catch (error) {
            dispatch(commonSlice.actions.hasStatus(0))
            dispatch(commonSlice.actions.hasError(error.message));
        }
    }
}

export const commonClearMessages = () => {
    return async () => {
        dispatch(commonSlice.actions.hasSuccess(null))
        dispatch(commonSlice.actions.hasStatus(0))
        dispatch(commonSlice.actions.hasError(null))
        dispatch(commonSlice.actions.setActionState(''))
        dispatch(commonSlice.actions.setButtonState(Date.now()))
    }
}