import React, { useState } from 'react'
import { useNavigate } from 'react-router';
import { Dialog, Slide, DialogContent } from '@mui/material'
import success from '../assets/img/popup.gif';

const Transition = React.forwardRef(function Transition(props, ref) {
	return <Slide direction="up" ref={ref} {...props} />;
});

export default function PopupModal() {
	const navigate = useNavigate();
	const [open, setOpen] = useState(true)
	return (
		<Dialog open={open} onClose={setOpen} TransitionComponent={Transition} keepMounted className="fixed inset-0  bg-opacity-75 transition-opacity data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in relative z-10">
			<div className="fixed inset-0 z-10 w-screen overflow-y-auto">
				<div className="flex min-h-full items-center justify-center p-4 text-center sm:p-0">
					<DialogContent
						transition
						className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all data-[closed]:translate-y-4 data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in sm:my-8 sm:w-full sm:max-w-[843px] data-[closed]:sm:translate-y-0 data-[closed]:sm:scale-95"
					>
						<div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
							<div className="flex flex-col items-center">
								<img src={success} alt='success' className="mb-[-1rem] mt-[-1rem] w-[200px]" />
								<p className="text-[24px] font-semibold text-[#000] text-center mb-4">
									Thank you for your interest in our Market Research Program. A staff member of AeeDee will contact you by email or phone within 2 business days. We appreciate your patience.
								</p>
							</div>
						</div>
						<div className="bg-gray-50 px-4 py-3 flex justify-center sm:px-6">
							<button
								type="button"
								data-autofocus
								onClick={() => {
									setOpen(false)
									navigate('/');
								}}
								style={{
									background: 'linear-gradient(87.72deg, #1A87DD 19.43%, #023D6B 97.58%)',
									color: '#ffffff', // Set text color for better contrast
								}}
								className="mt-4 mb-4 inline-flex w-full justify-center rounded-[32px] px-3 py-2 text-sm font-semibold shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-opacity-80 sm:mt-0 sm:w-[307px]"
							>
								Go back to Home
							</button>
						</div>
					</DialogContent>
				</div>
			</div>
		</Dialog>

	)
}