import * as React from 'react';
import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';
import './loader.css';

const Loader = () => {
	return (
		<div className="min-h-screen max-w-[1024px] mx-auto pt-[62px]">
			<div className="relative">
				<div className="absolute custom-bg-size bg-no-repeat custom-bg-position inset-0 opacity-30" >  </div>
				<div className="flex justify-center items-center  min-h-screen" >
					<div className="container 2xl:max-w-[1280px] 3xl:max-w-[1536px] skeleton-loder pages-container  bg-[#000] rounded-b-[15px] opacity-[0.70] w-full px-[20px] md:px-[50px] md:w-1/2 lg:w-[96%] mt-[12.2rem] lg:mt-[12rem] xl:mt-[7rem] 5xl:mt-[-10rem]" style={{ overflow: 'hidden' }}>
						<Stack spacing={1}>
							<Skeleton variant="text" height={200} className="banner-loader" />

							<Skeleton variant="circular" width={175} height={175} />
							<Skeleton className="letter-box" variant="rounded" width={210} height={60} />
							<Skeleton className="letter-box-2" variant="rounded" width={210} height={60} />
							<Skeleton className="letter-box-3" variant="rounded" width={210} height={60} />
							<div className="links">
								<Skeleton className="letter-box-4" variant="rounded" width={210} height={60} />
								<Skeleton className="letter-box-4" variant="rounded" width={210} height={60} />
							</div>
							<Skeleton className="small-box" variant="rounded" width={210} height={60} />
							<Skeleton variant="rectangular" width={210} height={60} />
							<Skeleton className="small-box" variant="rounded" width={210} height={60} />
							<Skeleton variant="rounded" width={210} height={60} />
							<Skeleton className="small-box-sec" variant="rounded" width={210} height={60} />
							<Skeleton className="small-box" variant="rounded" width={210} height={60} />
							<Skeleton className="mediam-box" variant="rounded" width={210} height={60} />
						</Stack>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Loader
