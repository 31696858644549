import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react'
import { Routes, Route, Outlet, useLocation } from "react-router-dom";
import ScrollToTop from './utils/scrolltotop';
import Snackbar from './utils/snackbar';
import Header from "./Components/header"
import Footer from "./Components/footer"
import NewFooter from './Components/footer/Newfooter';
import Home from './Home';
import Contacts from './Contacts';
import Partner from './Partner';
import Pages from './Pages';
import Profile from './Profile';
import Faq from './Components/faq';
import Market from './MarketResearch';
import Advertisers from './Pages/Advertisers';
import GetVerified from './Pages/GetVerified';
import PageNotFound from './Components/404page'
import ProtectedRoute from './utils/protectedRoute';
import EventEmitter from './utils/event';
import { useDispatch, useSelector } from './store';
import { getGeneralSettings } from './store/auth';
import './App.css';

const PageLayout = ({ settingsDetails }) => {
	// const { profileid } = useParams();
	const location = useLocation();
	const [hideFooter, setHideFooter] = useState(0);
	useEffect(() => {
		if (location?.pathname === '/') {
			setHideFooter(0)
		} else {
			setHideFooter(1)
		}
	}, [hideFooter, location])

	return (
		<ProtectedRoute>
			<ScrollToTop>
				<Snackbar />
				<Header />
				<Outlet />
				{hideFooter === 0 ? <Footer settingsDetails={settingsDetails} /> : <NewFooter settingsDetails={settingsDetails} />}
			</ScrollToTop>
		</ProtectedRoute>
	)
};

PageLayout.propTypes = {
	settingsDetails: PropTypes.object
}

function App(props) {
	const dispatch = useDispatch();
	const [profileDetails, setProfileDetails] = useState({})
	const [settingsDetails, setSettingsDetails] = useState({})
	const { profile, generalSettings } = useSelector((state) => state.auth);

	useEffect(() => {
		dispatch(getGeneralSettings(`user/site/general-settings`, {}))
	}, [dispatch])

	useEffect(() => {
		setProfileDetails(profile);
		EventEmitter.subscribe('profileDetails', (profile) => {
			setProfileDetails(profile);
		})
	}, [profile])

	useEffect(() => {
		setSettingsDetails(generalSettings)
	}, [generalSettings])

	return (
		<Routes>
			<Route element={<PageLayout profileDetails={profileDetails} settingsDetails={settingsDetails} />}>
				<Route exact path={"/"} element={<Home {...props} settingsDetails={settingsDetails} />} />
				<Route exact path={"/contact-us"} element={<Contacts {...props} settingsDetails={settingsDetails} />} />
				<Route exact path={"/become-a-partner"} element={<Partner {...props} settingsDetails={settingsDetails} />} />
				<Route exact path={"/faq"} element={<Faq {...props} settingsDetails={settingsDetails} />} />
				<Route exact path={"/pages/:slug"} element={<Pages {...props} settingsDetails={settingsDetails} />} />
				<Route exact path={"/market-research"} element={<Market {...props} settingsDetails={settingsDetails} />} />
				<Route exact path={"/advertisers"} element={<Advertisers {...props} settingsDetails={settingsDetails} />} />
				<Route exact path={"/get-verified"} element={<GetVerified {...props} settingsDetails={settingsDetails} />} />
			</Route>
			<Route exact path={"/profile/:profileid"} element={<Profile {...props} settingsDetails={settingsDetails} />} />
			<Route exact path={"/:profileid"} element={<Profile {...props} settingsDetails={settingsDetails} />} />
			{/* <Route path={'/login'} element={<Login />} /> */}
			<Route path="*" element={<PageNotFound />} />
		</Routes>
	);
}

export default App;
