import { createSlice } from '@reduxjs/toolkit';
import axiosServices from '../utils/axios';
import { dispatch } from './index';

const initialState = {
    errorMessage: null,
    successMessage: null,
    respStatus: 0,
    pageDetails: {}
};

const pageSlice = createSlice({
    name: 'page',
    initialState,
    reducers: {
        hasError(state, action) {
            state.errorMessage = action.payload;
        },
        setPage(state, action) {
            state.pageDetails = action.payload;
        },
        hasSuccess(state, action) {
            state.successMessage = action.payload;
        },
        hasStatus(state, action) {
            state.respStatus = action.payload;
        }
    }
});

export default pageSlice.reducer;
export const getPage = (apiUrl, slug) => {
    return async () => {
        try {
            const resp = await axiosServices.get(`${apiUrl}?slug=${slug}`, {});
            const { status, response} = resp.data;
            dispatch(pageSlice.actions.hasStatus(status))
            if (status && status === 1) {
                dispatch(pageSlice.actions.setPage(response));
            }
        } catch (error) {
            dispatch(pageSlice.actions.setPage({}));
        }
    }
}

export const clearPage = () => {
    return async () => {
        dispatch(pageSlice.actions.setPage({}));
    }
}