import PropTypes from 'prop-types';
import React, { useEffect, useState } from "react";
const ProtectedRoute = ({children}) => {
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const checkUserToken = () => {
        setIsLoggedIn(true);
    }
    useEffect(() => {
        checkUserToken();
    }, [isLoggedIn]);

    return (
        <React.Fragment>{children}</React.Fragment>
    );
}

ProtectedRoute.propTypes = {
    children: PropTypes.any
}

export default ProtectedRoute;